import { ASSET_TYPE_ID } from "@/types";

export function pieChartColor(id?: number) {
  const color = staticPieChartColors[id as ASSET_TYPE_ID];
  if (color) {
    return color;
  }
  return lightGrey;
}

export const staticPieChartColors: { [key in ASSET_TYPE_ID]: string } = {
  [ASSET_TYPE_ID.VEHICLE]: "#54B399", // Viz / $euiColorVis0 - green,
  [ASSET_TYPE_ID.EQUIPMENT]: "#6092C0", // Viz / $euiColorVis1 - blue,
  [ASSET_TYPE_ID.TRAILER]: "#D6BF57", // Viz / $euiColorVis5 - yellow,
  [ASSET_TYPE_ID.ATTACHMENT]: "#DA8B45", // Viz / $euiColorVis7 - orange,
  [ASSET_TYPE_ID.BUCKET]: "#E7664C", // Viz / $euiColorVis8 - red,
  [ASSET_TYPE_ID.SMALL_TOOL]: "#AA6556", // Viz / $euiColorVis8 - brown,
};

export const staticPieChartLabels: { [key in ASSET_TYPE_ID]: string } = {
  [ASSET_TYPE_ID.VEHICLE]: "Vehicles",
  [ASSET_TYPE_ID.EQUIPMENT]: "Equipment",
  [ASSET_TYPE_ID.TRAILER]: "Trailers",
  [ASSET_TYPE_ID.ATTACHMENT]: "Attachments",
  [ASSET_TYPE_ID.BUCKET]: "Buckets",
  [ASSET_TYPE_ID.SMALL_TOOL]: "Small Tools",
};

export function pieChartLabel(id?: number) {
  const label = staticPieChartLabels[id as ASSET_TYPE_ID];
  if (label) {
    return label;
  }
  return "Unknown";
}

export const lightGrey = "#F2F5F7"; // $euiColorLightestShade - light grey

"use client";

import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { ActionCard } from "@/components/get-started/action-cards/action-card";
import { GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";

type DrawGeofencesCardProps = GetStartedCardProps;

export const DrawGeofencesCard = ({ state }: DrawGeofencesCardProps) => {
  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });
  return (
    <ActionCard
      handleAction={() => {
        heaperEvent(
          "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
          { step: "Draw Geofences", source: "getStarted" },
        );

        sendMessageToParentWindow(T3_PATHNAME.CREATE_GEOFENCES);
      }}
      state={state}
      subtitle="Create boundaries to monitor your jobsites or other locations on the map."
      title="Draw Geofences"
    />
  );
};

"use client";
import { EuiLoadingSpinner } from "@equipmentshare/ds2";
import {
  Asset,
  Camera,
  CLIENT_CONSTANTS,
  Tracker,
  useSearchAssetsQuery,
  useSearchCamerasQuery,
  useSearchTrackersQuery,
  useUpdateAssetMutation,
} from "@fleet-configuration/client";
import {
  FlyoutForm,
  useFlyout,
  useToastContext,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { debounce, pollWithBackoff } from "@fleet-configuration/utils";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { useDeviceInstallStatusContext } from "@/app/providers/DeviceInstallStatusProvider";
import {
  AssignDevicesForm,
  AssignDevicesFormFields,
} from "@/components/forms/assign-devices";
import { ActionCard } from "@/components/get-started/action-cards/action-card";
import { GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";
import { AssignDevicesFlyoutProps } from "@/config/constants/flyout";
import { usePendingAssignments } from "@/contexts";
import { DeviceKind, DeviceType } from "@/types";
import { handleAPIError } from "@/utils/validation";

type AssignDevicesCardProps = GetStartedCardProps;

export const DEFAULT_DEVICE_VALUES = {
  assetId: "",
  cameraId: "",
  deviceType: DeviceType.Tracker,
  trackerId: "",
};

type ByDevice<T> = { [key in DeviceType]: T };

const initialAssets = {
  [DeviceType.Tracker]: [],
  [DeviceType.Camera]: [],
};

const initialShowNoAssetsCallout = {
  [DeviceType.Tracker]: false,
  [DeviceType.Camera]: false,
};

const initialAssignedAssetIds = {
  [DeviceType.Tracker]: [],
  [DeviceType.Camera]: [],
};

export const AssignDevicesCard = ({
  handleCloseAction = () => {},
  state,
}: AssignDevicesCardProps) => {
  const {
    isFlyoutVisible,
    closeFlyout,
    openFlyout,
    flyoutProps,
    setFlyoutProps,
  } = useFlyout<AssignDevicesFlyoutProps>(
    CONSTANTS.FlyoutContextKey.ASSIGN_DEVICES,
  );

  const { refetch: refetchDeviceInstallStatus } =
    useDeviceInstallStatusContext();

  const formMethods = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: DEFAULT_DEVICE_VALUES,
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    reset: resetForm,
    setValue,
    watch,
  } = formMethods;

  const { setPendingAssignment } = usePendingAssignments();

  const [updateAsset] = useUpdateAssetMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [hasTrackers, setHasTrackers] = useState(false);
  const [isLoadingTrackers, setIsLoadingTrackers] = useState(false);
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  const [showNoTrackersCallout, setShowNoTrackersCallout] = useState(false);

  const [hasCameras, setHasCameras] = useState(false);
  const [isLoadingCameras, setIsLoadingCameras] = useState(false);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [showNoCamerasCallout, setShowNoCamerasCallout] = useState(false);

  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const deviceType = watch("deviceType");
  const isTrackerType = deviceType === DeviceType.Tracker;
  const isCameraType = deviceType === DeviceType.Camera;

  const [assets, _setAssets] = useState<ByDevice<Asset[]>>(initialAssets);
  const [showNoAssetsCallout, _setShowNoAssetsCallout] = useState<
    ByDevice<boolean>
  >(initialShowNoAssetsCallout);

  // used for caching successful assignments to combat eventual consistency in the API
  const [assignedAssetIds, _setAssignedAssetIds] = useState<ByDevice<number[]>>(
    initialAssignedAssetIds,
  );
  const [assignedTrackerIds, setAssignedTrackerIds] = useState<number[]>([]);
  const [assignedCameraIds, setAssignedCameraIds] = useState<number[]>([]);

  /*
   * Asset state wrappers to handle per device type state manipulation.
   */

  function setAssets(_assets: Asset[]) {
    _setAssets({ ...assets, [deviceType]: _assets });
  }

  function setAssignedAssetIds(assetIds: number[]) {
    _setAssignedAssetIds({ ...assignedAssetIds, [deviceType]: assetIds });
  }

  function setShowNoAssetsCallout(value: boolean) {
    _setShowNoAssetsCallout({ ...showNoAssetsCallout, [deviceType]: value });
  }

  const {
    data: trackerData,
    fetchMore: fetchMoreTrackers,
    loading: isLoadingTrackerData,
  } = useSearchTrackersQuery({
    variables: {
      input: {
        query: "",
        ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
      },
    },
  });
  const {
    data: cameraData,
    fetchMore: fetchMoreCameras,
    loading: isLoadingCameraData,
  } = useSearchCamerasQuery({
    variables: {
      input: {
        query: "",
        ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
      },
    },
  });
  const { fetchMore: fetchMoreAssets } = useSearchAssetsQuery({
    skip: true,
  });
  const { showErrorToast, showToast } = useToastContext();

  useEffect(() => {
    if (!isFlyoutVisible) {
      return;
    }
    if (!isLoadingCameraData) {
      setHasCameras(!!cameraData?.searchCameras.items?.length);
    }
    if (!isLoadingTrackerData) {
      const _hasTrackers = !!trackerData?.searchTrackers.items?.length;
      setHasTrackers(_hasTrackers);

      // override the default tracker type to camera if there are no trackers or if there is a preselected camera
      if (!_hasTrackers || flyoutProps.device?.kind === DeviceKind.CAMERA) {
        setValue("deviceType", DeviceType.Camera);
      }
    }
  }, [
    cameraData,
    isLoadingCameraData,
    isLoadingTrackerData,
    setValue,
    trackerData,
    isFlyoutVisible,
    flyoutProps.device?.kind,
  ]);

  const fetchAssets = debounce(async (queryString?: string) => {
    try {
      setIsLoadingAssets(true);

      const assetsWithoutDevice = await fetchMoreAssets({
        variables: {
          input: {
            query: queryString || "",
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
          filter: isTrackerType ? { trackerIds: null } : { cameraIds: null },
        },
      });

      let assetsData = assetsWithoutDevice?.data?.searchAssets.items ?? [];

      // filter out any ids that have been assigned in this flyout session
      const unassignedAssets = assetsData.filter(
        (a) => !assignedAssetIds[deviceType]!.includes(a.assetId),
      );

      setAssets(unassignedAssets);
      // there are no unassigned assets - set the callout and exit early
      if (!unassignedAssets?.length && !queryString) {
        setShowNoAssetsCallout(true);
        return;
      } else {
        setShowNoAssetsCallout(false);
      }
    } catch (error: any) {
      handleAPIError(error, showErrorToast);
    } finally {
      setIsLoadingAssets(false);
    }
  });

  const fetchTrackers = debounce(async (queryString?: string) => {
    try {
      setIsLoadingTrackers(true);
      const results = await fetchMoreTrackers({
        variables: {
          input: {
            query: queryString || "",
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
          filter: { assetIds: null },
        },
      });
      let trackersData = results?.data?.searchTrackers.items ?? [];
      // filter out any ids that have been assigned in this flyout session
      const unassignedTrackers = trackersData.filter(
        (t) => !assignedTrackerIds.includes(t.trackerId),
      );
      setTrackers(unassignedTrackers);

      // there are no unassigned trackers - set the callout and exit early
      if (!unassignedTrackers?.length && !queryString) {
        setShowNoTrackersCallout(true);
        return;
      } else {
        setShowNoTrackersCallout(false);
      }
    } catch (error: any) {
      handleAPIError(error, showErrorToast);
    } finally {
      setIsLoadingTrackers(false);
    }
  });

  const fetchCameras = debounce(async (queryString?: string) => {
    try {
      setIsLoadingCameras(true);
      const results = await fetchMoreCameras({
        variables: {
          input: {
            query: queryString || "",
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
          filter: { assetIds: null },
        },
      });

      let camerasData = results?.data?.searchCameras.items ?? [];
      // filter out any ids that have been assigned in this flyout session
      const unassignedCameras = camerasData.filter(
        (c) => !assignedCameraIds.includes(c.cameraId),
      );
      setCameras(unassignedCameras);

      // there are no unassigned cameras - set the callout and exit early
      if (!unassignedCameras?.length && !queryString) {
        setShowNoCamerasCallout(true);
        return;
      } else {
        setShowNoCamerasCallout(false);
      }
    } catch (error: any) {
      handleAPIError(error, showErrorToast);
    } finally {
      setIsLoadingCameras(false);
    }
  });

  useEffect(() => {
    if (!isFlyoutVisible) {
      return;
    }

    (isTrackerType || flyoutProps?.device?.kind === DeviceKind.TRACKER) &&
      void fetchTrackers(flyoutProps?.device?.deviceSerial ?? "");
    (isCameraType || flyoutProps?.device?.kind === DeviceKind.CAMERA) &&
      void fetchCameras(flyoutProps?.device?.deviceSerial ?? "");

    void fetchAssets();

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFlyoutVisible,
    deviceType,
    assignedAssetIds,
    assignedTrackerIds,
    assignedCameraIds,
    flyoutProps?.device,
  ]);

  useEffect(() => {
    // Reset assetId whenever deviceType changes
    setValue("assetId", "");
    // clears errors when deviceType changes
    clearErrors();
  }, [deviceType, setValue, clearErrors]);

  const onSubmit: SubmitHandler<AssignDevicesFormFields> = async (data) => {
    const assetId = Number(data.assetId);
    const trackerId = Number(data.trackerId);
    const cameraId = Number(data.cameraId);
    const onSuccessToast = (deviceType: string, id: number) => {
      showToast({
        color: "success",
        text: `${deviceType} ${id} was assigned to an asset.`,
        title: `${deviceType} assigned!`,
      });
    };

    if (data.deviceType === DeviceType.Tracker) {
      await updateAsset({
        variables: {
          input: {
            trackerId,
          },
          assetId,
        },
      });

      onSuccessToast(DeviceType.Tracker, trackerId);

      // API HACKS BELOW
      // cache the assigned ids to filter from dropdowns immediately
      setAssignedTrackerIds([...assignedTrackerIds, trackerId]);
      setAssignedAssetIds([...(assignedAssetIds[deviceType] || []), assetId]);
      setPendingAssignment(trackerId, assetId);

      // eagerly set no trackers callout when assigning the last tracker
      // to get ahead of API eventual consistency
      // unless there is a preselected tracker, in which case we don't want to show the callout
      if (trackers.length === 1 && !flyoutProps?.device) {
        setShowNoTrackersCallout(true);
      }
      if (assets[deviceType].length === 1) {
        setShowNoAssetsCallout(true);
      }
    } else if (data.deviceType === DeviceType.Camera) {
      await updateAsset({
        variables: {
          input: {
            cameraId,
          },
          assetId,
        },
      });

      onSuccessToast(DeviceType.Camera, cameraId);

      // API HACKS BELOW
      // cache the assigned ids to filter from dropdowns immediately
      setAssignedCameraIds([...assignedCameraIds, cameraId]);
      setAssignedAssetIds([...assignedAssetIds[deviceType], assetId]);
      setPendingAssignment(cameraId, assetId);

      // eagerly set no cameras callout when assigning the last camera
      // to get ahead of API eventual consistency
      // unless there is a preselected camera, in which case we don't want to show the callout
      if (cameras.length === 1 && !flyoutProps?.device) {
        setShowNoCamerasCallout(true);
      }
      if (assets[deviceType].length === 1) {
        setShowNoAssetsCallout(true);
      }
    }
  };

  const trackSave = (
    data: AssignDevicesFormFields,
    saveType: "save" | "saveAndAddAnother",
  ) => {
    heaperEvent(
      "Fleet Configuration Dashboard - Get Started - Click - Complete Assign Devices Step",
      {
        device_type: data.deviceType,
        save_type: saveType,
      },
    );
  };

  async function handleSave(opts: { closeFlyout: boolean }) {
    await handleSubmit(async (data) => {
      setIsSubmitting(true);
      try {
        await onSubmit(data);
        trackSave(data, opts.closeFlyout ? "save" : "saveAndAddAnother");
        // poll for device install status after successful assignment
        pollWithBackoff(refetchDeviceInstallStatus, {
          limit: 10,
          waitMs: 1_000,
        });
      } catch (e: any) {
        handleAPIError(e, showErrorToast);
        // return early to keep form open
        return;
      } finally {
        setFlyoutProps({ ...flyoutProps, device: undefined }); // clear device from flyout props, since it has already been assigned
        setIsSubmitting(false);
      }
      // reset form inputs, but keep deviceType the same
      if (!opts.closeFlyout) {
        resetForm({
          ...DEFAULT_DEVICE_VALUES,
          deviceType: deviceType,
        });
      }
      if (opts.closeFlyout) {
        handleCloseFlyout();
      }
    })();
  }

  const handleSaveAndClose = async () => {
    await handleSave({ closeFlyout: true });
  };

  const handleSaveAndContinue = async () => {
    await handleSave({ closeFlyout: false });
    handleCloseAction();
  };

  const handleCloseFlyout = () => {
    handleCloseAction();
    closeFlyout();
    // reset the form to default values
    resetForm({ ...DEFAULT_DEVICE_VALUES });
    // reset assigned asset ids for all device types
    _setAssignedAssetIds(initialAssignedAssetIds);
    setAssignedTrackerIds([]);
    setAssignedCameraIds([]);
  };

  const actionTitle = "Assign Devices";
  return (
    <FormProvider {...formMethods}>
      <ActionCard
        handleAction={() => {
          heaperEvent(
            "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
            { step: "Assign Devices", source: "getStarted" },
          );
          openFlyout();
        }}
        state={state}
        subtitle="Match your installed devices to assets and begin gathering important asset data."
        title={actionTitle}
        unavailableText="If you have devices, this feature is unlocked by adding an asset."
      />
      <FlyoutForm
        flyoutBody={
          isLoadingCameraData || isLoadingTrackerData ? (
            <EuiLoadingSpinner
              data-testid="loading-assign-devices-form"
              size="xl"
            />
          ) : (
            <AssignDevicesForm
              assets={assets[deviceType]}
              cameras={cameras}
              control={control}
              deviceType={deviceType}
              hasCameras={hasCameras}
              hasTrackers={hasTrackers}
              isLoadingAssets={isLoadingAssets}
              isLoadingDevices={isLoadingTrackers || isLoadingCameras}
              isSubmitting={isSubmitting}
              onSearchAssetsChange={fetchAssets}
              onSearchCamerasChange={fetchCameras}
              onSearchTrackersChange={fetchTrackers}
              onSubmit={() => handleSubmit(onSubmit)}
              // TODO: update below to use KIND
              preselectedCameraId={
                flyoutProps?.device?.kind === DeviceKind.CAMERA
                  ? flyoutProps.device.esId
                  : undefined
              }
              preselectedTrackerId={
                flyoutProps?.device?.kind === DeviceKind.TRACKER
                  ? flyoutProps.device.esId
                  : undefined
              }
              showNoAssetsCallout={showNoAssetsCallout[deviceType]}
              showNoCamerasCallout={showNoCamerasCallout}
              showNoTrackersCallout={showNoTrackersCallout}
              trackers={trackers}
            />
          )
        }
        handleClose={handleCloseFlyout}
        hasRequiredFields
        headerText={actionTitle}
        hideOverlay={flyoutProps?.hideOverlay ?? false}
        isVisible={isFlyoutVisible}
        primaryAction={{ action: handleSaveAndClose }}
        secondaryAction={{
          action: handleSaveAndContinue,
          label: "Save & Assign Another",
        }}
        tertiaryAction={{ action: handleCloseFlyout }}
      />
    </FormProvider>
  );
};

import { EuiForm, EuiLink } from "@equipmentshare/ds2";
import {
  CheckboxGroupField,
  Select,
  TextField,
} from "@fleet-configuration/components";
import { EXTERNAL_URLS } from "@fleet-configuration/utils";
import { Control, Controller, useWatch } from "react-hook-form";

import { useUser } from "@/hooks/useUser";
import { GQL, Permission, Role } from "@/types";
import { getGrantablePermissions } from "@/utils/permissions";

export type PermissionFormFields = {
  readOnly: boolean;
  hideAssetDetails: boolean;
  canRent: boolean;
  canGrantPermissions?: boolean;
  canReadAssetFinancialRecords?: boolean;
  canCreateAssetFinancialRecords?: boolean;
  canAccessCamera?: boolean;
};

export type AddUserFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  permissions: PermissionFormFields;
};

type AddUsersFormProps = {
  control: Control<AddUserFormFields>;
  onSubmit: () => void;
};

const roleOptions = [
  { value: Role.USER, text: "User" },
  { value: Role.MANAGER, text: "Manager" },
];

export const canReadAssetFinancialRecordsPermission = {
  id: Permission.CAN_READ_ASSET_FINANCIAL_RECORDS,
  label: "Can Read Asset Financial Records",
};
export const canCreateAssetFinancialRecordsPermission = {
  id: Permission.CAN_CREATE_ASSET_FINANCIAL_RECORDS,
  label: "Can Create Asset Financial Records",
};
export const canAccessCameraPermission = {
  id: Permission.CAN_ACCESS_CAMERA,
  label: "Can Access Camera Media",
};
export const canGrantPermissionsPermission = {
  id: Permission.CAN_GRANT_PERMISSIONS,
  label: "Can Grant Permissions",
};
export const readOnlyPermission = {
  id: Permission.READ_ONLY,
  label: "Read-Only User",
};
export const hideAssetDetailsPermission = {
  id: Permission.HIDE_ASSET_DETAILS,
  label: "Hide Asset Details",
};
export const canRentPermission = {
  id: Permission.CAN_RENT,
  label: "Can Rent",
};

export const permissionsAvailableToAllUsers = [
  readOnlyPermission,
  hideAssetDetailsPermission,
  canRentPermission,
];

const permissionToOptions: {
  [key in Permission]: { id: string; label: string };
} = {
  [Permission.READ_ONLY]: readOnlyPermission,
  [Permission.HIDE_ASSET_DETAILS]: hideAssetDetailsPermission,
  [Permission.CAN_RENT]: canRentPermission,
  [Permission.CAN_GRANT_PERMISSIONS]: canGrantPermissionsPermission,
  [Permission.CAN_READ_ASSET_FINANCIAL_RECORDS]:
    canReadAssetFinancialRecordsPermission,
  [Permission.CAN_CREATE_ASSET_FINANCIAL_RECORDS]:
    canCreateAssetFinancialRecordsPermission,
  [Permission.CAN_ACCESS_CAMERA]: canAccessCameraPermission,
};

function getPermissionOptions(user: GQL.User, role: Role) {
  return getGrantablePermissions(user, role).map((p) => permissionToOptions[p]);
}

export const AddUsersForm = ({ control, onSubmit }: AddUsersFormProps) => {
  const { user } = useUser();
  const selectedRole = useWatch({
    control,
    name: "role",
  });

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <EuiForm data-testid="add-users-form" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="firstName"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          return (
            <TextField
              errorMessage={error?.message}
              isInvalid={invalid}
              isRequired
              label="First Name"
              onChange={onChange}
              value={value}
            />
          );
        }}
        rules={{
          required: { value: true, message: "First name is required." },
        }}
      />
      <Controller
        control={control}
        name="lastName"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          return (
            <TextField
              errorMessage={error?.message}
              isInvalid={invalid}
              isRequired
              label="Last Name"
              onChange={onChange}
              value={value}
            />
          );
        }}
        rules={{ required: { value: true, message: "Last name is required." } }}
      />
      <Controller
        control={control}
        name="email"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          return (
            <TextField
              errorMessage={error?.message}
              isInvalid={invalid}
              isRequired
              label="Email"
              onChange={onChange}
              value={value}
            />
          );
        }}
        rules={{
          required: { value: true, message: "Email is required." },
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid email.",
          },
        }}
      />
      <Controller
        control={control}
        name="role"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          return (
            <Select
              castValueToNumber
              errorMessage={error?.message}
              isInvalid={invalid}
              isRequired
              label="Role"
              labelAppend={
                <EuiLink
                  href={EXTERNAL_URLS.T3_PERMISSIONS_HELP}
                  target="_blank"
                >
                  Learn More
                </EuiLink>
              }
              onChange={onChange}
              options={roleOptions}
              value={value}
            />
          );
        }}
        rules={{ required: { value: true, message: "Role is required." } }}
      />
      <Controller
        control={control}
        name="permissions"
        render={({ field: { value, onChange } }) => {
          return (
            <CheckboxGroupField
              label="Permissions"
              onChange={onChange}
              options={getPermissionOptions(
                user,
                parseInt(selectedRole as any as string),
              )}
              value={value}
            />
          );
        }}
      />
    </EuiForm>
  );
};

import { EuiFilterButton, EuiFilterGroup } from "@equipmentshare/ds2";
import { Facet } from "@fleet-configuration/client";
import { useFlyout } from "@fleet-configuration/components";
import React, { useEffect, useState } from "react";

import { FiltersFlyout } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout";
import { FilterState } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filters";
import { CONSTANTS } from "@/config/constants";

export type WithFilterService = {
  filterService: {
    filterState: FilterState;
    updateFilters: (filters: FilterState) => void;
    facets: Facet[];
  };
};

export const FilterButton = ({ filterService }: WithFilterService) => {
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const { openFlyout } = useFlyout(CONSTANTS.FlyoutContextKey.FILTERS);

  const hasActiveFilters = activeFilterCount > 0;

  useEffect(() => {
    if (filterService.filterState) {
      setActiveFilterCount(
        Object.entries(filterService.filterState).filter(
          ([_, value]) => value.length > 0,
        ).length,
      );
    }
  }, [filterService.filterState]);

  return (
    <>
      <EuiFilterGroup>
        <EuiFilterButton
          data-testid="selected-filters-count"
          grow={false}
          hasActiveFilters={hasActiveFilters}
          iconSide="left"
          iconType="filterInCircle"
          numActiveFilters={hasActiveFilters ? activeFilterCount : undefined}
          numFilters={hasActiveFilters ? undefined : 0}
          onClick={openFlyout}
        >
          Filters
        </EuiFilterButton>
      </EuiFilterGroup>
      <FiltersFlyout filterService={filterService} />
    </>
  );
};

import { useMeQuery } from "@fleet-configuration/client";
import { useCallback, useEffect, useState } from "react";

export const useLocalStorage = <LocalStorageData extends object>(
  stateKey: string,
) => {
  const { data: userData } = useMeQuery();

  const getDataFromLocalStorage = useCallback(
    (key: string): LocalStorageData => {
      return JSON.parse(localStorage.getItem(key) ?? "{}");
    },
    [],
  );

  const getCompositeKey = useCallback(() => {
    return [stateKey, userData?.me?.companyId].join("_");
  }, [stateKey, userData?.me?.companyId]);

  const setDataInLocalStorage = useCallback(
    (data: LocalStorageData) => {
      setLocalStorageData(data);
      localStorage.setItem(getCompositeKey(), JSON.stringify(data));
    },
    [getCompositeKey],
  );

  const [localStorageData, setLocalStorageData] = useState<LocalStorageData>();

  useEffect(() => {
    if (!userData?.me?.companyId) {
      return;
    }

    const dataFromLocalStorage = getDataFromLocalStorage(getCompositeKey());

    setLocalStorageData(dataFromLocalStorage);
  }, [
    userData?.me?.companyId,
    stateKey,
    getDataFromLocalStorage,
    getCompositeKey,
  ]);

  return {
    localStorageData,
    setDataInLocalStorage,
  };
};

import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import { FacetId } from "@fleet-configuration/client";
import {
  DonutWithCenterMetric,
  Tooltip,
  useFlyout,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { EXTERNAL_URLS } from "@fleet-configuration/utils";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

import { useDeviceInstallStatusContext } from "@/app/providers/DeviceInstallStatusProvider";
import { StatusPanel } from "@/components/device-installation-progress/device-install-status/status-panel";
import { StatusPanelProps } from "@/components/device-installation-progress/device-install-status/status-panel";
import { aggregateDeviceInstallStatus } from "@/components/device-installation-progress/device-install-status/utils";
import { FilterState } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filters";
import { WidgetWrapper } from "@/components/widget-wrapper";
import { CONSTANTS } from "@/config/constants";
import { deviceInstallStatusDataVizConfigs } from "@/config/deviceStatus";
import { type DeviceInstallStatusItem } from "@/hooks/useDeviceInstallStatus";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { DeviceInstallStatusEnum } from "@/types/gql";

export const CHART_SIZE_MAP = {
  xsmall: "250px",
  small: "250px",
  med: "250px",
  large: "240px",
};

export const DeviceInstallStatus = () => {
  const isXsmall = useIsWithinBreakpoints(["xs"]);
  const isSmall = useIsWithinBreakpoints(["s"]);
  const isMed = useIsWithinBreakpoints(["m"]);

  const getContainerHeight = useCallback(() => {
    if (isXsmall) return CHART_SIZE_MAP.xsmall;
    if (isSmall) return CHART_SIZE_MAP.small;
    if (isMed) return CHART_SIZE_MAP.med;
    return CHART_SIZE_MAP.large;
  }, [isMed, isSmall, isXsmall]);

  const [containerHeight, setContainerHeight] = useState(getContainerHeight());

  // Set the container height based on the breakpoint
  useEffect(() => {
    const containerHeight = getContainerHeight();
    setContainerHeight(containerHeight);
  }, [getContainerHeight, isXsmall, isSmall, isMed]);

  const { deviceInstallStatus, loading, error } =
    useDeviceInstallStatusContext();

  const { openFlyout } = useFlyout(CONSTANTS.FlyoutContextKey.YOUR_DEVICES);

  const { setDataInLocalStorage } = useLocalStorage<FilterState>(
    CONSTANTS.LOCAL_STORAGE.FILTER_STATE_BASE_KEY,
  );

  const [aggregatedInstallStatus, setAggregatedInstallStatus] = useState<
    Array<DeviceInstallStatusItem>
  >([]);

  const prevDeviceInstallStatusItemsRef = useRef(
    [] as DeviceInstallStatusItem[],
  );

  /**
   * Aggregate device install status data for use within the <DonutWithCenterMetric /> component.
   * Run a deep comparison of the current and previous device install status items to determine
   * if the data has changed to prevent unnecessary re-renders.
   */
  useEffect(() => {
    const currentItems = deviceInstallStatus?.deviceInstallStatusItems;
    const prevItems = prevDeviceInstallStatusItemsRef.current;

    if (currentItems && !isEqual(currentItems, prevItems)) {
      const incompleteItems = aggregateDeviceInstallStatus({
        aggregateName: "Incomplete",
        aggregateColor: deviceInstallStatusDataVizConfigs.INCOMPLETE.color,
        aggregateDetail: deviceInstallStatusDataVizConfigs.INCOMPLETE.detail,
        data: currentItems,
        facet: [
          DeviceInstallStatusEnum.Unassigned,
          DeviceInstallStatusEnum.Undetected,
        ],
        // Total Incomplete = Total Devices - Total Complete
        valueOverride:
          deviceInstallStatus.totalCount -
          (currentItems.find((i) => i.id === DeviceInstallStatusEnum.Complete)
            ?.value || 0),
      });

      const completeItems = aggregateDeviceInstallStatus({
        aggregateName: DeviceInstallStatusEnum.Complete,
        aggregateColor: deviceInstallStatusDataVizConfigs.COMPLETE.color,
        aggregateDetail: deviceInstallStatusDataVizConfigs.COMPLETE.detail,
        data: currentItems,
        facet: [DeviceInstallStatusEnum.Complete],
      });

      setAggregatedInstallStatus([
        completeItems as DeviceInstallStatusItem,
        incompleteItems as DeviceInstallStatusItem,
      ]);

      prevDeviceInstallStatusItemsRef.current = currentItems;
    }
  }, [
    deviceInstallStatus?.deviceInstallStatusItems,
    deviceInstallStatus.totalCount,
  ]);

  const handleStatusPanelClickWithStatusFilter = (
    status: DeviceInstallStatusEnum,
  ) => {
    setDataInLocalStorage({
      [FacetId.DeviceInstallStatus]: [status],
    });
    handleStatusPanelClick();
  };

  const handleStatusPanelClick = () => {
    openFlyout();
  };

  const handleManageUndetected = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Install Progress - Click - Manage Undetected",
      {},
    );
    handleStatusPanelClickWithStatusFilter(DeviceInstallStatusEnum.Undetected);
  };

  const handleManageUnassigned = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Install Progress - Click - Manage Unassigned",
      {},
    );
    handleStatusPanelClickWithStatusFilter(DeviceInstallStatusEnum.Unassigned);
  };

  const handleViewDevices = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Install Progress - Click - View Devices",
      {},
    );
    handleStatusPanelClick();
  };

  const getStatusCount = (status: DeviceInstallStatusEnum) => {
    return (
      deviceInstallStatus?.deviceInstallStatusItems?.find(
        (statusItem) => statusItem.label === status,
      )?.value ?? 0
    );
  };

  const renderTooltipContent = ({
    trigger,
    detail,
    testId,
  }: {
    trigger: string;
    detail: string;
    testId: string;
  }) => (
    <Tooltip
      anchorPosition="downCenter"
      panelStyle={{ width: "236px" }}
      testIdSuffix={testId}
      trigger={
        <EuiText className="cursor-pointer underline" color="subdued" size="xs">
          {trigger}
        </EuiText>
      }
    >
      <p>{detail}</p>
    </Tooltip>
  );

  const renderStatusPanel = (
    status: StatusPanelProps["status"],
    handleClick: StatusPanelProps["onClick"],
  ) => (
    <EuiFlexItem className="flex min-w-full flex-col">
      <StatusPanel
        hasError={!!error}
        isLoading={loading}
        onClick={handleClick}
        status={status}
        statusCount={getStatusCount(status)}
      />
    </EuiFlexItem>
  );

  return (
    <WidgetWrapper
      subTitle={
        <span>
          Track your progress as you complete the necessary device install
          steps.{" "}
          <EuiLink
            data-testid="device-install-status-learn-more"
            external
            href={EXTERNAL_URLS.T3_DEVICE_INSTALL_STATUS_HELP}
            style={{ whiteSpace: "nowrap" }}
            target="_blank"
          >
            Learn more
          </EuiLink>
        </span>
      }
      title="Device Install Overview"
    >
      <EuiFlexGrid columns={3} gutterSize="m">
        {renderStatusPanel(DeviceInstallStatusEnum.Complete, handleViewDevices)}
        {renderStatusPanel(
          DeviceInstallStatusEnum.Undetected,
          handleManageUndetected,
        )}
        {renderStatusPanel(
          DeviceInstallStatusEnum.Unassigned,
          handleManageUnassigned,
        )}
      </EuiFlexGrid>
      <EuiSpacer size="s" />
      <EuiFlexGroup
        alignItems="flexStart"
        gutterSize="s"
        justifyContent="flexStart"
      >
        <EuiFlexItem grow={false}>
          <EuiText color="subdued" size="xs">
            <strong>Cameras:</strong> Complete ={" "}
            {renderTooltipContent({
              trigger: "Assigned",
              detail:
                "Devices must be assigned to their asset in T3 so the asset can be tracked.",
              testId: "assigned",
            })}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText color="subdued" size="xs">
            <strong>Trackers:</strong> Complete ={" "}
            {renderTooltipContent({
              trigger: "Detected",
              detail:
                "A detected device has reported its location at least once.",
              testId: "detected",
            })}{" "}
            and Assigned
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem grow={false}>
          <EuiText size="s">
            <h3>Installation Progress</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <DonutWithCenterMetric
            containerDimensions={{
              containerHeight,
              containerMinHeight: containerHeight,
            }}
            data={aggregatedInstallStatus}
            innerRadius={0.7}
            metricLabel="Total devices"
            metricValue={deviceInstallStatus.totalCount}
            showTooltip
            testId="devices-pie-chart"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </WidgetWrapper>
  );
};

import { useMeQuery } from "@fleet-configuration/client";

import env, { MockUserPermissionType } from "@/env";
import { GQL, Role } from "@/types";

/**
 * Wrapper function to return additional permissions for the user based on
 * mock data settings.
 */
function getPermissions(user: GQL.User): GQL.Permissions {
  // return 'OWNER_MANAGER' permissions overrides if set
  if (env.mocks?.userPermissionsType === MockUserPermissionType.OWNER_MANAGER) {
    return {
      ...user.permissions,
      ...{
        isCompanyOwner: true,
        securityLevelId: Role.MANAGER,
      },
    };
  }

  // return default permissions associated with user
  return user.permissions;
}

export const useUser = (): { user: GQL.User | undefined } => {
  const { data, loading } = useMeQuery({ fetchPolicy: "cache-first" });

  if (loading || !data) {
    return {
      user: undefined,
    };
  }

  return {
    user: {
      ...data.me,
      permissions: getPermissions(data.me),
    },
  };
};

import {
  EuiBadge,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import { DataVizBaseDatum } from "@fleet-configuration/components";

import {
  pieChartColor,
  pieChartLabel,
} from "@/components/fleet-overview/pie-chart-with-legend/utils";
import { ASSET_TYPE_ID } from "@/types";

export const defaultDataTypes: DataVizBaseDatum[] = Object.values(ASSET_TYPE_ID)
  .filter((v) => typeof v === "number")
  .map((id) => ({
    id: String(id as ASSET_TYPE_ID),
    color: pieChartColor(id as ASSET_TYPE_ID),
    label: pieChartLabel(id as ASSET_TYPE_ID),
    value: 0,
  }));

export type PieChartLegendProps = {
  data: DataVizBaseDatum[];
  isLoading: boolean;
};

export const PieChartLegend = (props: PieChartLegendProps) => {
  const emptyData = !props.isLoading && !props.data.length;
  const legendData = emptyData ? defaultDataTypes : props.data;
  const dataTestId = emptyData ? "default-legend" : "pie-chart-legend";

  const justifyFlexStart = useIsWithinBreakpoints(["s", "m"]);
  const columnCount = useIsWithinBreakpoints(["s", "m", "xl"]) ? 2 : 1;

  return props.isLoading ? (
    <EuiSkeletonText data-testid="legend-skeleton" lines={3} />
  ) : (
    <EuiFlexGroup
      alignItems="center"
      data-testid={dataTestId}
      gutterSize="s"
      justifyContent={justifyFlexStart ? "flexStart" : "center"}
      responsive={false}
      wrap
    >
      <EuiFlexGrid columns={columnCount} gutterSize="s">
        {legendData?.map((data) => (
          <EuiFlexItem key={data.id}>
            <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiBadge
                  color={data.color}
                  data-testid={`badge-${data.id}-${data.label}`}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText size="xs">{data.label}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </EuiFlexGroup>
  );
};

"use client";

import { useGetDefaultBranchLazyQuery } from "@fleet-configuration/client";
import { useToastContext } from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { ActionCard } from "@/components/get-started/action-cards/action-card";
import { GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";

type UpdateBranchInformationCardProps = GetStartedCardProps;

export const UpdateBranchInformationCard = ({
  state,
}: UpdateBranchInformationCardProps) => {
  const [getDefaultBranch] = useGetDefaultBranchLazyQuery({
    fetchPolicy: "cache-first",
  });
  const { showErrorToast } = useToastContext();

  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });

  async function handleAction() {
    const { error, data } = await getDefaultBranch();
    if (error || !data) {
      const message = "Could not fetch default branch information";
      showErrorToast({
        title: message,
      });
      return;
    }
    heaperEvent(
      "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
      { step: "Update Branch Information", source: "getStarted" },
    );
    sendMessageToParentWindow(
      `${T3_PATHNAME.EDIT_BRANCH}/${data.getDefaultBranch.branchId}`,
    );
  }

  return (
    <ActionCard
      handleAction={handleAction}
      state={state}
      subtitle="Finalize branch details to begin managing onsite operations."
      title="Update Branch Information"
    />
  );
};

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
} from "@equipmentshare/ds2";
import { Device, DeviceInstallStatus } from "@fleet-configuration/client";

import { deviceStatusConfigs } from "@/config/deviceStatus";
import { DeviceKind } from "@/types";

export const DetectedStatus = ({ isDetected }: { isDetected: boolean }) => {
  const statusConfig = isDetected
    ? deviceStatusConfigs.detected
    : deviceStatusConfigs.undetected;

  return (
    <EuiFlexGroup
      alignItems="center"
      data-testid="detected-status"
      gutterSize="none"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon
          color={statusConfig.color}
          data-testid="detected-icon"
          size="m"
          type={statusConfig.icon}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText
          color="subdued"
          size="xs"
          style={{
            paddingRight: "10px",
          }}
        >
          {statusConfig.text}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export const AssignedStatus = ({ assigned }: { assigned: boolean }) => {
  const statusConfig = assigned
    ? deviceStatusConfigs.assigned
    : deviceStatusConfigs.unassigned;

  return (
    <EuiFlexGroup
      alignItems="center"
      data-testid="assigned-status"
      gutterSize="none"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon
          color={statusConfig.color}
          data-testid="assigned-icon"
          size="m"
          type={statusConfig.icon}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText color="subdued" size="xs">
          {statusConfig.text}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

type DeviceInstallStatusesProps = {
  deviceKind: Device["kind"];
  installStatuses: DeviceInstallStatus[];
  isPendingAssignment?: boolean;
};

export const DeviceInstallStatuses = ({
  deviceKind,
  installStatuses,
  isPendingAssignment,
}: DeviceInstallStatusesProps) => {
  if (!installStatuses.length) {
    return null;
  }

  return (
    <EuiFlexGroup
      alignItems="center"
      data-testid="device-install-statuses"
      gutterSize="xs"
      responsive={false}
    >
      {deviceKind === DeviceKind.TRACKER && (
        <EuiFlexItem grow={false}>
          <DetectedStatus
            isDetected={installStatuses?.includes(DeviceInstallStatus.Detected)}
          />
        </EuiFlexItem>
      )}
      <EuiFlexItem>
        <AssignedStatus
          assigned={
            installStatuses?.includes(DeviceInstallStatus.Assigned) ||
            !!isPendingAssignment
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

import { FacetId } from "@fleet-configuration/client";
import { FlyoutForm, useFlyout } from "@fleet-configuration/components";

import { WithFilterService } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filter-button";
import { Filters } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filters";
import { CONSTANTS } from "@/config/constants";

export const FiltersFlyout = ({ filterService }: WithFilterService) => {
  const { isFlyoutVisible, closeFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.FILTERS,
  );

  const clearAllFilters = () => {
    filterService.updateFilters({
      [FacetId.DeviceKind]: [],
      [FacetId.DeviceType]: [],
      [FacetId.DeviceInstallStatus]: [],
    });
  };

  return (
    <FlyoutForm
      flyoutBody={<Filters filterService={filterService} />}
      handleClose={closeFlyout}
      headerText="All Filters"
      hideOverlay={true}
      isVisible={isFlyoutVisible}
      primaryAction={{
        label: "Close",
        action: closeFlyout,
      }}
      tertiaryAction={{ label: "Clear all filters", action: clearAllFilters }}
    />
  );
};

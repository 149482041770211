import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
} from "@equipmentshare/ds2";
import { Asset } from "@fleet-configuration/client";
import {
  EXTERNAL_URLS,
  replaceStringPart,
  T3_PATHNAME,
} from "@fleet-configuration/utils";
import React from "react";

export enum FieldName {
  VIN = "VIN",
  SERIAL = "Serial Number",
}

type FieldInUseModalProps = {
  assetsUsingField: Asset[];
  onContinue: () => void;
  onClose: () => void;
  fieldName: FieldName;
};

const getBaseUrl = () => {
  return `${EXTERNAL_URLS.T3}/#`;
};

export const FieldInUseModal = ({
  assetsUsingField,
  fieldName,
  onContinue,
  onClose,
}: FieldInUseModalProps) => {
  const onlyOneAsset = assetsUsingField.length === 1;
  const baseUrl = getBaseUrl();
  const asset = assetsUsingField[0];

  const assetAction = () => {
    if (onlyOneAsset) {
      window.open(
        `${baseUrl}${replaceStringPart(T3_PATHNAME.EDIT_ASSET, asset.assetId)}`,
        "_blank",
      );
    } else {
      window.open(`${baseUrl}/assets/assets`, "_blank");
    }
  };

  return (
    <EuiModal data-testid="field-in-use-modal" onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{`${fieldName} Already In Use`}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody data-testid="modal-body">
        {onlyOneAsset ? (
          <>
            <EuiText>This {fieldName} is used by the following asset:</EuiText>
            <EuiSpacer size="m" />
            <EuiText>
              <strong>Asset Name:</strong> {asset.name}
            </EuiText>
            <EuiSpacer size="s" />
            <EuiText>
              <strong>Make:</strong> {asset.make?.name}
            </EuiText>
            <EuiSpacer size="s" />
            <EuiText>
              <strong>Model:</strong> {asset.model?.name || asset.customModel}
            </EuiText>
            <EuiSpacer size="s" />
          </>
        ) : (
          <EuiText>
            This {fieldName} is used by {assetsUsingField.length} assets
          </EuiText>
        )}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onContinue}>
          {fieldName === FieldName.SERIAL ? "Save as new asset" : "Continue"}
        </EuiButtonEmpty>
        <EuiButton fill onClick={assetAction}>
          {onlyOneAsset ? "View Existing Asset" : "View Assets"}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

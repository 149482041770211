import {
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiHorizontalRule,
  EuiText,
  htmlIdGenerator,
} from "@equipmentshare/ds2";
import { Camera3LineIcon, HardDrive2LineIcon } from "@equipmentshare/ds2-icons";
import { Asset, Camera, Tracker } from "@fleet-configuration/client";
import {
  ComboBoxField,
  TileMenu,
  TileMenuItemOption,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { useEffect } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";

import {
  NoAssetsCallout,
  NoCamerasCallout,
  NoTrackersCallout,
} from "@/components/forms/callouts";
import { DeviceType, GQL } from "@/types";
import { getMakeModelSerialVin } from "@/utils/helpers";

export type AssignDevicesFormFields = {
  assetId: string;
  cameraId: string;
  deviceType: DeviceType;
  trackerId: string;
};

export type AssignDevicesFormProps = {
  assets: Asset[];
  cameras: Camera[];
  control: Control<AssignDevicesFormFields>;
  deviceType: DeviceType;
  isLoadingAssets: boolean;
  isLoadingDevices: boolean;
  isSubmitting: boolean;
  hasCameras: boolean;
  hasTrackers: boolean;
  onSearchAssetsChange: (searchQuery: string) => void;
  onSearchCamerasChange: (searchQuery: string) => void;
  onSearchTrackersChange: (searchQuery: string) => void;
  onSubmit: () => void;
  preselectedTrackerId?: string;
  preselectedCameraId?: string;
  showNoAssetsCallout: boolean;
  showNoCamerasCallout: boolean;
  showNoTrackersCallout: boolean;
  trackers: Tracker[];
};

export const getTrackerOptions = (trackers: GQL.Tracker[]) => {
  return trackers.map((tracker) => ({
    label: tracker.deviceSerial,
    value: String(tracker.trackerId),
  })) as EuiComboBoxOptionOption[];
};

export const getCameraOptions = (cameras: GQL.Camera[]) => {
  return cameras.map((camera) => ({
    label: camera.deviceSerial,
    value: String(camera.cameraId),
  })) as EuiComboBoxOptionOption[];
};

export const getAssetOptions = (assetsData: GQL.Asset[]) => {
  return assetsData?.map((asset) => ({
    label: asset.name,
    value: String(asset.assetId),
  })) as EuiComboBoxOptionOption[];
};

export const AssignDevicesForm = ({
  assets,
  cameras,
  control,
  deviceType,
  isLoadingAssets,
  isLoadingDevices,
  isSubmitting,
  hasCameras,
  hasTrackers,
  onSearchAssetsChange,
  onSearchCamerasChange,
  onSearchTrackersChange,
  onSubmit,
  preselectedCameraId,
  preselectedTrackerId,
  showNoAssetsCallout,
  showNoCamerasCallout,
  showNoTrackersCallout,
  trackers,
}: AssignDevicesFormProps) => {
  const isTrackerType = deviceType === DeviceType.Tracker;
  const isCameraType = deviceType === DeviceType.Camera;

  const trackerOptions = getTrackerOptions(trackers);
  const cameraOptions = getCameraOptions(cameras);
  const assetOptions = getAssetOptions(assets);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (preselectedTrackerId) {
      setValue("trackerId", preselectedTrackerId);
    }
    if (preselectedCameraId) {
      setValue("cameraId", preselectedCameraId);
    }
  }, [preselectedTrackerId, preselectedCameraId, setValue]);

  useEffect(() => {
    heaperEvent(
      "Fleet Configuration Dashboard - Get Started - Load - Assign Devices Form",
      {
        all_assets_assigned: showNoAssetsCallout,
        all_trackers_assigned: showNoTrackersCallout,
        all_cameras_assigned: showNoCamerasCallout,
      },
    );
  }, [showNoAssetsCallout, showNoCamerasCallout, showNoTrackersCallout]);

  return (
    <EuiForm data-testid="assign-devices-form" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="deviceType"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          const tileMenuOptions: TileMenuItemOption[] = [
            {
              icon: HardDrive2LineIcon,
              key: htmlIdGenerator()(),
              isDisabled: !hasTrackers,
              label: DeviceType.Tracker,
            },
            {
              icon: Camera3LineIcon,
              key: htmlIdGenerator()(),
              isDisabled: !hasCameras,
              label: "Camera",
            },
          ];

          return (
            <TileMenu
              data-testid="device-type-tile-menu"
              errorMessage={error?.message}
              isInvalid={invalid}
              isRequired
              label="Device Type"
              onChange={onChange}
              onHoverInfoText={
                !hasCameras || !hasTrackers
                  ? "Device type not found if disabled"
                  : undefined
              }
              options={tileMenuOptions}
              value={value}
            />
          );
        }}
        rules={{
          required: { value: true, message: "Device type is required." },
        }}
      />
      <EuiHorizontalRule />
      {showNoTrackersCallout && isTrackerType && <NoTrackersCallout />}
      {showNoCamerasCallout && isCameraType && <NoCamerasCallout />}
      {showNoAssetsCallout &&
        // don't show the no assets callout when the no trackers / cameras callout is shown
        ((isTrackerType && !showNoTrackersCallout) ||
          (isCameraType && !showNoCamerasCallout)) && (
          <NoAssetsCallout deviceType={deviceType} />
        )}

      <Controller
        control={control}
        name="trackerId"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          if (isTrackerType) {
            const renderTrackerOption = (option: EuiComboBoxOptionOption) => {
              const tracker = trackers?.find(
                (tracker) => String(tracker.trackerId) === option.value,
              );

              return (
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiFlexItem>
                    <EuiText size="s">
                      <strong>{tracker?.deviceSerial}</strong>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="s">{tracker?.trackerType?.name}</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            };

            return (
              <ComboBoxField
                errorMessage={error?.message}
                helpText="Only unassigned trackers listed"
                isDisabled={isSubmitting || showNoTrackersCallout}
                isInvalid={invalid}
                isLoading={isLoadingDevices}
                isRequired
                label="Tracker"
                onChange={onChange}
                onSearchChange={onSearchTrackersChange}
                options={trackerOptions}
                placeholder={
                  showNoTrackersCallout
                    ? "No unassigned trackers available"
                    : "Search by serial number"
                }
                renderOption={renderTrackerOption}
                rowHeight={60}
                testId="tracker-combo-box"
                value={value}
              />
            );
          }
          return <></>;
        }}
        rules={{
          required: isTrackerType
            ? { value: true, message: "Tracker is required." }
            : false,
        }}
      />

      <Controller
        control={control}
        name="cameraId"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          if (isCameraType) {
            const renderCameraOption = (option: EuiComboBoxOptionOption) => {
              const camera = cameras?.find(
                (camera) => String(camera.cameraId) === option.value,
              );

              return (
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiFlexItem>
                    <EuiText size="s">
                      <strong>{camera?.deviceSerial}</strong>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="s">{camera?.cameraType?.name}</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            };

            return (
              <ComboBoxField
                errorMessage={error?.message}
                helpText="Only unassigned cameras listed"
                isDisabled={isSubmitting || showNoCamerasCallout}
                isInvalid={invalid}
                isLoading={isLoadingDevices}
                isRequired
                label="Camera"
                onChange={onChange}
                onSearchChange={onSearchCamerasChange}
                options={cameraOptions}
                placeholder={
                  showNoCamerasCallout
                    ? "No unassigned cameras available"
                    : "Search by serial number"
                }
                renderOption={renderCameraOption}
                rowHeight={60}
                testId="camera-combo-box"
                value={value}
              />
            );
          }
          return <></>;
        }}
        rules={{
          required: isCameraType
            ? { value: true, message: "Camera is required." }
            : false,
        }}
      />

      <Controller
        control={control}
        name="assetId"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          const renderAssetOption = (option: EuiComboBoxOptionOption) => {
            const asset = assets.find(
              (asset) => String(asset.assetId) === option.value,
            );
            const assetDetails = asset && getMakeModelSerialVin(asset);

            return (
              <EuiFlexGroup direction="column" gutterSize="none">
                <EuiFlexItem>
                  <EuiText size="s">
                    <strong>{asset?.name}</strong>
                  </EuiText>
                </EuiFlexItem>
                {assetDetails && (
                  <EuiFlexItem>
                    <EuiText size="s">{assetDetails}</EuiText>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            );
          };

          return (
            <ComboBoxField
              errorMessage={error?.message}
              helpText="Only unassigned assets listed"
              // disable the asset dropdown when the no trackers / no cameras callout is shown
              // disable when the no assets callout is shown
              isDisabled={
                isSubmitting ||
                (isTrackerType && showNoTrackersCallout) ||
                (isCameraType && showNoCamerasCallout) ||
                showNoAssetsCallout
              }
              isInvalid={invalid}
              isLoading={isLoadingAssets}
              isRequired
              label="Asset"
              onChange={onChange}
              onSearchChange={onSearchAssetsChange}
              options={assetOptions}
              placeholder={
                showNoAssetsCallout
                  ? "No unassigned assets available"
                  : "Select asset"
              }
              renderOption={renderAssetOption}
              rowHeight={60}
              testId="asset-combo-box"
              value={value}
            />
          );
        }}
        rules={{ required: { value: true, message: "Asset is required." } }}
      />
    </EuiForm>
  );
};

import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import {
  CLIENT_CONSTANTS,
  Device,
  FacetId,
  SearchDevicesQuery,
  useSearchDevicesQuery,
} from "@fleet-configuration/client";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  COLORS,
  debounce,
  useIsComponentVisible,
} from "@fleet-configuration/utils";
import { useCallback, useEffect, useState } from "react";

import { DeviceCard } from "@/components/device-installation-progress/your-devices-flyout/device-card";
import { DeviceDetailsFlyout } from "@/components/device-installation-progress/your-devices-flyout/device-details";
import { FilterButton } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filter-button";
import { FilterState } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filters";
import { CONSTANTS } from "@/config/constants";
import { useLocalStorage } from "@/hooks/useLocalStorage";

export const mapFilterStateToGqlFilters = (filterState: FilterState) => {
  return Object.entries(filterState).flatMap(([facetId, values]) => {
    return values.map((value) => {
      return {
        facetId,
        value: value.toUpperCase(),
      };
    });
  });
};

export const YourDevicesList = () => {
  const {
    clearIsVisible,
    isVisible: isLastDeviceVisible,
    ref,
  } = useIsComponentVisible();
  const [lastResults, setLastResults] = useState<
    SearchDevicesQuery | undefined
  >();
  const [deviceItems, setDeviceItems] = useState<Device[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    localStorageData: filterStateFromLocalStorage,
    setDataInLocalStorage,
  } = useLocalStorage<FilterState>(
    CONSTANTS.LOCAL_STORAGE.FILTER_STATE_BASE_KEY,
  );

  const { fetchMore } = useSearchDevicesQuery({ skip: true });

  const isSmallScreen = useIsWithinBreakpoints(["xs", "s"]);

  const setInitialDeviceItems = useCallback(
    async (query: string = "") => {
      if (filterStateFromLocalStorage === undefined) {
        // Return early if filters haven't been retrieved from local storage yet
        return;
      }

      const { data } = await fetchMore({
        variables: {
          input: {
            query: query,
            filters: mapFilterStateToGqlFilters(filterStateFromLocalStorage),
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
        },
      });

      const installStatusFilterFromLocalStorage =
        filterStateFromLocalStorage[FacetId.DeviceInstallStatus];

      // Check if any of the install status filters in local storage have no devices associated with them, and remove them from local storage if so
      if (installStatusFilterFromLocalStorage?.length) {
        const installStatusFacet = data?.searchDevices?.facets?.find(
          (facet) => facet.facetId === FacetId.DeviceInstallStatus,
        );

        const statusesToRemoveFromLocalStorage =
          installStatusFilterFromLocalStorage.filter((status) => {
            return (
              installStatusFacet?.entries.find((entry) => entry.id === status)
                ?.count === 0
            );
          });

        if (statusesToRemoveFromLocalStorage.length) {
          const updatedFilterState = {
            ...filterStateFromLocalStorage,
            [FacetId.DeviceInstallStatus]:
              installStatusFilterFromLocalStorage.filter(
                (status) => !statusesToRemoveFromLocalStorage.includes(status),
              ),
          };

          setDataInLocalStorage(updatedFilterState);
        }
      }

      setDeviceItems(data?.searchDevices.items);
      setLastResults(data);
    },
    [fetchMore, filterStateFromLocalStorage, setDataInLocalStorage],
  );

  const setMoreDeviceItems = useCallback(async () => {
    setIsLoadingMore(true);
    const { data } = await fetchMore({
      variables: {
        input: {
          query: searchQuery,
          filters: mapFilterStateToGqlFilters(
            filterStateFromLocalStorage ?? {},
          ),
          ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          pageNumber: (lastResults?.searchDevices.pageNumber ?? 1) + 1,
        },
      },
    });

    setIsLoadingMore(false);
    setDeviceItems([...deviceItems, ...data?.searchDevices.items]);
    setLastResults(data);
  }, [
    fetchMore,
    deviceItems,
    setLastResults,
    lastResults?.searchDevices,
    searchQuery,
    filterStateFromLocalStorage,
  ]);

  useEffect(() => {
    // We want to use the current search query when the initial devices are set so that when they are _reset_ due to a filter change, the search query is still applied
    // but we do not want this effect to run every time the search query changes, hence it is omitted from the dependency array
    setInitialDeviceItems(searchQuery);

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInitialDeviceItems]);

  useEffect(() => {
    if (!lastResults) {
      return;
    }
    const moreItemsToLoad =
      lastResults?.searchDevices.totalItems &&
      deviceItems.length &&
      lastResults?.searchDevices.totalItems > deviceItems.length;

    if (isLastDeviceVisible && moreItemsToLoad) {
      setMoreDeviceItems();
      clearIsVisible();
    }
  }, [
    isLastDeviceVisible,
    clearIsVisible,
    setMoreDeviceItems,
    lastResults,
    lastResults?.searchDevices.totalItems,
    deviceItems.length,
  ]);

  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce(async (searchValue: string) => {
      heaperEvent(
        "Fleet Configuration Dashboard - Your Devices List - Load - Search Devices",
        { search_query: searchValue },
      );
      const { data } = await fetchMore({
        variables: {
          input: {
            query: searchValue,
            filters: mapFilterStateToGqlFilters(
              filterStateFromLocalStorage ?? {},
            ),
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
        },
      });

      setDeviceItems(data?.searchDevices.items);
      setLastResults(data);
    }),
    [filterStateFromLocalStorage],
  );

  return (
    <>
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{
          position: "fixed",
          zIndex: 1,
          width: "425px",
          marginTop: "-25px",
          marginLeft: "-25px",
          background: COLORS.LIGHTEST,
          padding: "12px",
        }}
      >
        <EuiFlexGroup gutterSize="xs">
          <EuiFlexItem grow={3}>
            <EuiFieldSearch
              aria-label="Search devices"
              isClearable
              onChange={(ev) => {
                onSearchChange(ev.target.value);
                setSearchQuery(ev.target.value);
              }}
              placeholder="Search by device serial number"
              value={searchQuery}
            />
          </EuiFlexItem>
          <FilterButton
            filterService={{
              filterState: filterStateFromLocalStorage!,
              updateFilters: setDataInLocalStorage,
              facets: lastResults?.searchDevices.facets ?? [],
            }}
          />
        </EuiFlexGroup>
        <EuiText color="subdued" data-testid="device-count">
          <strong>{"Devices found: "}</strong>
          {lastResults?.searchDevices.totalItems.toLocaleString()}
        </EuiText>
      </EuiFlexGroup>

      <EuiFlexGroup
        direction="column"
        gutterSize="none"
        style={{
          width: "425px",
          marginLeft: "-25px",
          paddingTop: isSmallScreen ? "120px" : "75px",
        }}
      >
        {deviceItems.map((device) => {
          return (
            <div
              key={
                device.deviceId +
                String(lastResults?.searchDevices.totalItems) +
                searchQuery
              }
              ref={ref}
            >
              <DeviceCard device={device} />
            </div>
          );
        })}
        {isLoadingMore && (
          <EuiFlexItem style={{ alignItems: "center" }}>
            <EuiSpacer size="m" />
            <EuiLoadingSpinner size="xxl" />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <DeviceDetailsFlyout />
    </>
  );
};

import { useEffect } from "react";

import eventEmitter from "@/app/eventEmitter";
import { GQL } from "@/types";

const useNotification = (
  notificationType: GQL.NotificationType,
  action: (notification: GQL.Notification) => void,
) => {
  useEffect(() => {
    eventEmitter.on(notificationType, action);

    return () => {
      eventEmitter.off(notificationType, action);
    };
  }, [notificationType, action]);
};

export default useNotification;

import { ConfigurationSteps, StepStatus } from "@fleet-configuration/client";

export type ConfigurationStep = keyof Omit<ConfigurationSteps, "__typename">;

export type WithState = {
  state: StepStatus;
};

export type WithAction = {
  handleAction: () => void;
};

export type GetStartedCardProps = WithState & {
  handleCloseAction?: () => void;
};

export type BaseActionCardProps = WithState & WithAction;

export enum StepStatusFilter {
  ALL = "ALL",
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
}

export enum FormStep {
  AssetTypeStep,
  AssetDetailsStep,
}

import { EuiFlexGroup, EuiSpacer } from "@equipmentshare/ds2";
import {
  Device,
  TrackerState,
  useSearchAssetsQuery,
} from "@fleet-configuration/client";
import { Accordion, DetailColumn } from "@fleet-configuration/components";
import { COLORS } from "@fleet-configuration/utils";

import { DeviceInstallStatuses } from "@/components/device-installation-progress/your-devices-flyout/device-install-statuses";
import { usePendingAssignments } from "@/contexts";
import { DeviceKind } from "@/types";
import { formattedDate, formattedTimeAndDate } from "@/utils/helpers";

export type ConnectivityStatusProps = {
  device: Device;
  isLoadingTrackerStateData?: boolean;
  trackerState?: TrackerState | null;
};

export const ConnectivityStatus = ({
  device,
  isLoadingTrackerStateData = false,
  trackerState,
}: ConnectivityStatusProps) => {
  const {
    assetId,
    esId,
    kind: deviceKind,
    installDate,
    installStatuses,
  } = device;
  const { pendingAssignments } = usePendingAssignments();
  const isPendingAssignment = !!pendingAssignments[esId];

  const pendingOrCurrentAssetId = assetId || pendingAssignments[esId];

  const { data: assetData, loading: isLoadingAssetData } = useSearchAssetsQuery(
    {
      variables: {
        input: {
          query: "",
        },
        filter: { assetIds: [pendingOrCurrentAssetId!] },
      },
      skip: !pendingOrCurrentAssetId, // Skip the query if assetId is not available
    },
  );

  const assetNameValue =
    assetData?.searchAssets?.items[0]?.name || "No asset assigned";

  // "Install Date" represents the device -> asset assignment in T3
  const dateDeviceAssignedToAsset = installDate
    ? formattedDate(installDate)
    : "--";
  const lastCellularContactDate = trackerState?.lastCellularContact
    ? formattedTimeAndDate(trackerState.lastCellularContact)
    : "--";
  const lastGpsSyncTimeAndDate = trackerState?.lastGPSSync
    ? formattedTimeAndDate(trackerState.lastGPSSync)
    : "--";

  return (
    <Accordion data-testid="connectivity-status" title="Connectivity Status">
      <EuiSpacer size="s" />
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ padding: "0 26px" }}
      >
        <EuiFlexGroup>
          <DetailColumn
            color={pendingOrCurrentAssetId ? undefined : COLORS.DANGER}
            isLoading={isLoadingAssetData}
            label="Assigned Asset"
            value={assetNameValue}
          />
          <DetailColumn
            label="Install Date"
            value={dateDeviceAssignedToAsset}
          />
        </EuiFlexGroup>

        {device.kind === DeviceKind.TRACKER && (
          <EuiFlexGroup>
            <DetailColumn
              isLoading={isLoadingTrackerStateData}
              label="Last Cellular Contact"
              value={lastCellularContactDate}
            />
            <DetailColumn
              isLoading={isLoadingTrackerStateData}
              label="Last GPS Sync"
              value={lastGpsSyncTimeAndDate}
            />
          </EuiFlexGroup>
        )}

        <EuiFlexGroup>
          <DetailColumn
            label="Install Status"
            onHoverInfoText="This shows the completion status of the steps necessary for the device to be considered installed in T3."
            value={
              device.installStatuses.length ? (
                <DeviceInstallStatuses
                  deviceKind={deviceKind}
                  installStatuses={installStatuses}
                  isPendingAssignment={!!isPendingAssignment}
                />
              ) : (
                "--"
              )
            }
          />
        </EuiFlexGroup>
      </EuiFlexGroup>
    </Accordion>
  );
};

"use client";

import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import { FeatureFlagGate } from "@fleet-configuration/components";
import { FEATURE_FLAGS } from "@fleet-configuration/utils";

import { DeviceInstallStatus } from "@/components/device-installation-progress/device-install-status";
import { YourDevicesFlyout } from "@/components/device-installation-progress/your-devices-flyout";
import { FleetOverview } from "@/components/fleet-overview";
import { GetStarted } from "@/components/get-started";
import { CONSTANTS } from "@/config/constants";
import { useUser as eagerlyPopulateUserCache } from "@/hooks/useUser";

export default function Dashboard() {
  eagerlyPopulateUserCache();

  const isMultiColumnLayout = useIsWithinBreakpoints(["l", "xl"]);

  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="m"
      style={{
        maxWidth: `${CONSTANTS.LAYOUT.MAX_APP_WIDTH}px`,
        margin: "0 auto",
      }}
    >
      <EuiText>
        <h1>Welcome to T3</h1>
        <p>
          Learn more about your fleet and dive into configuring your T3
          experience!
        </p>
      </EuiText>
      <EuiFlexGrid
        columns={isMultiColumnLayout ? 2 : 1}
        gutterSize="m"
        responsive={false}
      >
        <EuiFlexItem>
          <FleetOverview />
        </EuiFlexItem>
        <EuiFlexItem>
          <GetStarted />
        </EuiFlexItem>
        <EuiFlexItem>
          <FeatureFlagGate
            flag={FEATURE_FLAGS.enableFleetConfigurationDeviceOnboarding}
          >
            <DeviceInstallStatus />
            <YourDevicesFlyout />
          </FeatureFlagGate>
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiFlexGroup>
  );
}

/**
 * This file should be used for storing
 * static image asset meta for use in the following components:
 * -- Next Image
 * https://nextjs.org/docs/app/api-reference/components/image
 */

export type StaticImageAsset = {
  src: string;
  alt: string;
  width: number;
  height: number;
  blurDataURL?: string;
  fallback?: React.ReactNode;
};

export const USE_FALLBACK = "USE_FALLBACK";

export type StaticImageAssets = {
  cameras: {
    [key: string]: StaticImageAsset;
  };
  error: StaticImageAsset;
  fallback: StaticImageAsset;
  trackers: {
    [key: string]: StaticImageAsset;
  };
};

const dimensions = {
  width: 64,
  height: 64,
};

export const staticImageAsset: StaticImageAssets = {
  cameras: {
    t3DashCam: {
      src: "/staticAssets/cameras/t3DashCam.png",
      alt: "T3 Dash Cam image",
      ...dimensions,
    },
  },
  error: {
    src: "/staticAssets/error-wheelbarrow.png",
    alt: "An error occurred",
    width: 240,
    height: 268,
  },
  fallback: {
    src: "/staticAssets/fallback.png",
    alt: "Fallback device image",
    ...dimensions,
  },
  trackers: {
    fj2500: {
      src: "/staticAssets/trackers/fj2500.png",
      alt: "FJ2500 tracker image",
      ...dimensions,
    },
    gl520: {
      src: "/staticAssets/trackers/gl520.png",
      alt: "GL500/520 tracker image",
      ...dimensions,
    },
    mcx101: {
      src: "/staticAssets/trackers/mcx101.png",
      alt: "MCX101 tracker image",
      ...dimensions,
    },
    mc4plus: {
      src: "/staticAssets/trackers/mc4Plus.png",
      alt: "MC4+/MC4++ tracker image",
      ...dimensions,
    },
    slapAndTrack: {
      src: "/staticAssets/trackers/slapAndTrack.png",
      alt: "Slap-n-Track tracker image",
      ...dimensions,
    },
  },
};

import { EuiSkeletonCircle, EuiSkeletonLoading } from "@equipmentshare/ds2";
import {
  DataVizBaseDatum,
  type DataVizContainerDimensionsDefaults,
} from "@fleet-configuration/components";

import { PieChart } from "@/components/fleet-overview/pie-chart-with-legend/pie-chart";
import { lightGrey } from "@/components/fleet-overview/pie-chart-with-legend/utils";

export type PieChartWithSkeletonProps = {
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: DataVizBaseDatum[];
  isLoading: boolean;
};

export const PieChartWithSkeleton = (props: PieChartWithSkeletonProps) => {
  const noData = !props.isLoading && props.data.length === 0;
  const dataTestId = props.isLoading
    ? "pie-chart-with-skeleton"
    : noData
      ? "empty-pie-chart"
      : "pie-chart-with-data";

  const renderChart = () => {
    const emptyPieData = [
      {
        id: "empty",
        color: lightGrey,
        label: "",
        value: 100,
      },
    ];

    return (
      <PieChart
        containerDimensions={props.containerDimensions}
        data={noData ? emptyPieData : props.data}
        showArcLabels={noData ? false : true}
      />
    );
  };

  return (
    <EuiSkeletonLoading
      contentAriaLabel="pie chart"
      data-testid={dataTestId}
      isLoading={props.isLoading}
      loadedContent={renderChart()}
      loadingContent={
        <EuiSkeletonCircle
          data-testid="pie-chart-skeleton-circle"
          isLoading={props.isLoading}
          style={{
            margin: "16px",
            height: props.containerDimensions?.containerHeight ?? "100%",
            width: props.containerDimensions?.containerWidth ?? "100%",
          }}
        />
      }
    />
  );
};

import { EXTERNAL_URLS } from "@fleet-configuration/utils";

import { staticImageAsset } from "@/config/staticAssets";
import {
  TrackerInstallationGuideMap,
  TrackerInstallationGuideModel,
} from "@/types";

export const trackerInstallationGuides: Map<
  string,
  TrackerInstallationGuideMap
> = new Map();
trackerInstallationGuides
  .set(TrackerInstallationGuideModel.FJ2500, {
    model: TrackerInstallationGuideModel.FJ2500,
    description:
      "Utilization tracking device intended for heavy duty vehicles.",
    imgData: staticImageAsset.trackers.fj2500,
    guideUrl: EXTERNAL_URLS.TRACKER_INSTALL_GUIDE.FJ2500,
    isOwned: false,
  })
  .set(TrackerInstallationGuideModel.GL520, {
    model: TrackerInstallationGuideModel.GL520,
    description:
      "Non-utilization tracking device intended for reporting asset location.",
    imgData: staticImageAsset.trackers.gl520,
    guideUrl: EXTERNAL_URLS.TRACKER_INSTALL_GUIDE.GL520,
    isOwned: false,
  })
  .set(TrackerInstallationGuideModel.MC4, {
    model: TrackerInstallationGuideModel.MC4,
    description:
      "Versatile device reporting location, utilization, and diagnostic data. Fits light, medium, and heavy duty fleet.",
    imgData: staticImageAsset.trackers.mc4plus,
    guideUrl: EXTERNAL_URLS.TRACKER_INSTALL_GUIDE.MC4_PLUS,
    isOwned: false,
  })
  .set(TrackerInstallationGuideModel.MCX101, {
    model: TrackerInstallationGuideModel.MCX101,
    description:
      "Utilization tracking device intended for light duty vehicles.",
    imgData: staticImageAsset.trackers.mcx101,
    guideUrl: EXTERNAL_URLS.TRACKER_INSTALL_GUIDE.MCX101,
    isOwned: false,
  })
  .set(TrackerInstallationGuideModel.T3_DASH_CAM, {
    model: TrackerInstallationGuideModel.T3_DASH_CAM,
    description:
      "Driver safety device intended for reporting driver behavior and incident report footage.",
    imgData: staticImageAsset.cameras.t3DashCam,
    guideUrl: EXTERNAL_URLS.TRACKER_INSTALL_GUIDE.T3_DASH_CAM,
    isOwned: false,
  });

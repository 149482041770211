import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiImage,
} from "@equipmentshare/ds2";
import { useGetTrackerStateQuery } from "@fleet-configuration/client";
import {
  FlyoutForm,
  useFlyout,
  useToastContext,
} from "@fleet-configuration/components";
import { COLORS } from "@fleet-configuration/utils";
import { useEffect } from "react";

import { ConnectivityStatus } from "@/components/device-installation-progress/your-devices-flyout/device-details/connectivity-status";
import { DetailsButtons } from "@/components/device-installation-progress/your-devices-flyout/device-details/details-buttons";
import { DetailsInfo } from "@/components/device-installation-progress/your-devices-flyout/device-details/details-info";
import { CONSTANTS } from "@/config/constants";
import { DeviceDetailsFlyoutProps } from "@/config/constants/flyout";
import { getDeviceImage } from "@/config/deviceImages";
import { handleAPIError } from "@/utils/validation";

export const DeviceDetailsFlyout = ({}) => {
  const { isFlyoutVisible, closeFlyout, flyoutProps } =
    useFlyout<DeviceDetailsFlyoutProps>(
      CONSTANTS.FlyoutContextKey.DEVICE_DETAILS,
    );

  const { device } = flyoutProps;

  const {
    data: trackerStateData,
    error: getTrackerStateError,
    loading: isLoadingTrackerStateData,
  } = useGetTrackerStateQuery({
    variables: {
      trackerId: Number(device?.esId),
    },
    skip: device?.kind !== "TRACKER", // Skip the query if not a tracker kind
  });

  const { showErrorToast } = useToastContext();

  useEffect(() => {
    if (getTrackerStateError) {
      handleAPIError(getTrackerStateError, showErrorToast);
    }
    // Excluding 'showToast' from the dependencies array to prevent infinite loop
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackerStateError]);

  const trackerState = trackerStateData?.getTrackerState;
  const imageMetadata = getDeviceImage(device!);

  const deviceDetailsFlyoutBody = (
    <EuiFlexGroup
      css={{ margin: "-24px" }} // removes padding from the EuiFlyoutBody
      data-testid="device-details-flyout-body"
      direction="column"
      gutterSize="s"
    >
      <EuiFlexItem css={{ backgroundColor: COLORS.LIGHTEST }}>
        <EuiFlexGroup justifyContent="center">
          <EuiFlexItem grow={false}>
            {imageMetadata && (
              <EuiImage
                alt={imageMetadata.alt}
                src={imageMetadata.src}
                style={{ maxHeight: "240px" }}
              />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiHorizontalRule margin="none" />
      </EuiFlexItem>

      <DetailsButtons device={device} />
      <DetailsInfo
        device={device}
        isLoadingTrackerState={isLoadingTrackerStateData}
        trackerBatteryVoltage={trackerState?.trackerBatteryVoltage}
      />
      <ConnectivityStatus
        device={device}
        isLoadingTrackerStateData={isLoadingTrackerStateData}
        trackerState={trackerState}
      />
    </EuiFlexGroup>
  );

  return (
    <FlyoutForm
      data-testid="device-details-flyout"
      flyoutBody={deviceDetailsFlyoutBody}
      handleClose={closeFlyout}
      headerText={device?.deviceSerial!}
      hideOverlay={flyoutProps?.hideOverlay ?? true}
      isVisible={isFlyoutVisible}
      secondaryAction={{
        action: closeFlyout,
        label: "Close",
      }}
    />
  );
};

import {
  EuiBadge,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSkeletonLoading,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@equipmentshare/ds2";
import { MouseEvent, useEffect, useState } from "react";

import { deviceInstallStatusConfigs } from "@/config/deviceStatus";
import { DeviceInstallStatusEnum } from "@/types/gql";

export type StatusPanelStatus = Exclude<
  DeviceInstallStatusEnum,
  DeviceInstallStatusEnum.Assigned | DeviceInstallStatusEnum.Detected
>;

export type StatusPanelProps = {
  onClick: () => void;
  hasError?: boolean;
  isLoading?: boolean;
  status: Exclude<
    DeviceInstallStatusEnum,
    DeviceInstallStatusEnum.Assigned | DeviceInstallStatusEnum.Detected
  >;
  statusCount: string | number;
};

export const TEST_ID_PREFIX = "status-panel-";

export const StatusPanel = (props: StatusPanelProps) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (
      props.statusCount === 0 &&
      props.status !== DeviceInstallStatusEnum.Complete
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [props.status, props.statusCount]);

  const handleStatusPanelClick = (evt: MouseEvent) => {
    // Prevent the click event from bubbling up to the parent EuiPanel when used in the EuiButton
    evt.stopPropagation();
    return props.onClick();
  };

  const panelConfig = deviceInstallStatusConfigs[props.status];

  const renderLoadedContent = () => (
    <EuiPanel
      className="flex h-full flex-col text-left shadow"
      color={isDisabled ? "subdued" : panelConfig.color}
      data-testid={`${TEST_ID_PREFIX}${props.status}${isDisabled ? "-disabled" : ""}`}
      grow={false}
      paddingSize="m"
    >
      <EuiFlexGroup
        alignItems="flexStart"
        className="h-full"
        direction="column"
        gutterSize="xs"
        justifyContent="spaceBetween"
      >
        <EuiFlexItem grow={false}>
          <EuiBadge
            color={panelConfig.badgeColor}
            data-testid={`${TEST_ID_PREFIX}${props.status}-badge`}
            iconType={panelConfig.icon}
            isDisabled={isDisabled}
          >
            <EuiText>
              <h3 className="!text-sm !font-normal">{panelConfig.label}</h3>
            </EuiText>
          </EuiBadge>
          <EuiSpacer size="s" />
          <EuiTitle
            data-testid={`${TEST_ID_PREFIX}${props.status}-status-count`}
            size="m"
          >
            <p>
              {!props.hasError && !props.isLoading
                ? props.statusCount.toLocaleString()
                : "--"}
            </p>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={true}>
          <EuiText
            data-testid={`${TEST_ID_PREFIX}${props.status}-description`}
            size="xs"
          >
            <p>{panelConfig.description}</p>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            aria-label={panelConfig.linkText}
            data-testid={`${TEST_ID_PREFIX}${props.status}-link`}
            disabled={isDisabled}
            flush="left"
            iconSide="right"
            iconSize="s"
            iconType={"sortRight"}
            onClick={handleStatusPanelClick}
            style={{ blockSize: "20px" }}
          >
            <EuiText size="xs">
              <strong>{panelConfig.linkText}</strong>
            </EuiText>
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );

  return (
    <EuiSkeletonLoading
      announceLoadedStatus
      announceLoadingStatus
      className="flex flex-1 flex-col"
      contentAriaLabel={`Loading ${props.status} Panel`}
      data-testid={`${TEST_ID_PREFIX}-loading`}
      isLoading={props.isLoading && !props.hasError}
      loadedContent={renderLoadedContent()}
      loadingContent={
        <EuiSkeletonRectangle
          className="text-left shadow"
          data-testid={`${TEST_ID_PREFIX}${props.status}-loading`}
          height="145px"
          width="100%"
        />
      }
    />
  );
};

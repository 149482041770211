import { CheckLineIcon, CloseLineIcon } from "@equipmentshare/ds2-icons";
import { COLORS } from "@fleet-configuration/utils";

import { DeviceInstallationStatusColorType } from "@/types";
import { DeviceInstallStatusEnum } from "@/types/gql";

export const deviceStatusConfigs = {
  detected: {
    color: COLORS.SUCCESS,
    icon: CheckLineIcon,
    text: "Detected",
  },
  undetected: {
    color: COLORS.DANGER,
    icon: CloseLineIcon,
    text: "Undetected",
  },
  assigned: {
    color: COLORS.SUCCESS,
    icon: CheckLineIcon,
    text: "Assigned",
  },
  unassigned: {
    color: COLORS.DANGER,
    icon: CloseLineIcon,
    text: "Unassigned",
  },
};

export const deviceInstallStatusDataVizConfigs = {
  [DeviceInstallStatusEnum.Complete]: {
    color: COLORS.DEVICE_INSTALL_STATUS.STATUS_COMPLETE,
    detail: <p>Devices with all installation steps completed.</p>,
  },
  INCOMPLETE: {
    color: COLORS.DEVICE_INSTALL_STATUS.STATUS_DEFAULT,
    detail: (
      <p>
        Devices that are unassigned <strong>and/or</strong> undetected.
      </p>
    ),
  },
};

export const deviceInstallStatusConfigs = {
  [DeviceInstallStatusEnum.Complete]: {
    badgeColor: COLORS.DEVICE_INSTALL_STATUS.STATUS_COMPLETE,
    color: DeviceInstallationStatusColorType.COMPLETE,
    description: "Devices with all install steps completed.",
    icon: "check",
    label: "Complete",
    linkText: "View devices",
  },
  [DeviceInstallStatusEnum.Undetected]: {
    badgeColor: COLORS.DEVICE_INSTALL_STATUS.STATUS_DEFAULT,
    color: DeviceInstallationStatusColorType.UNDETECTED,
    description: "Trackers that have yet to report location.",
    icon: "cross",
    label: "Undetected",
    linkText: "Manage undetected",
  },
  [DeviceInstallStatusEnum.Unassigned]: {
    badgeColor: COLORS.DEVICE_INSTALL_STATUS.STATUS_DEFAULT,
    color: DeviceInstallationStatusColorType.UNASSIGNED,
    description: "Devices not assigned to an asset.",
    icon: "cross",
    label: "Unassigned",
    linkText: "Manage unassigned",
  },
};

import { Device } from "@fleet-configuration/client";

import { staticImageAsset } from "./staticAssets";

export const deviceImages = {
  fallback: staticImageAsset.fallback,
  FJ2500LA: staticImageAsset.trackers.fj2500,
  FJ2500LS: staticImageAsset.trackers.fj2500,
  fleetcam: staticImageAsset.cameras.t3DashCam,
  GL500M: staticImageAsset.trackers.gl520,
  GL520M: staticImageAsset.trackers.gl520,
  "MC-4+": staticImageAsset.trackers.mc4plus,
  "MC-4++": staticImageAsset.trackers.mc4plus,
  "MCX 101": staticImageAsset.trackers.mcx101,
  "Slap-n-Track": staticImageAsset.trackers.slapAndTrack,
};

export const getDeviceImage = (device?: Device) => {
  if (!device) {
    return deviceImages.fallback;
  }

  return (
    deviceImages[device.typeName as keyof typeof deviceImages] ||
    deviceImages.fallback
  );
};

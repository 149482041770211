import { ApolloError } from "@fleet-configuration/client";
import { ShowErrorCallbackType } from "@fleet-configuration/components";

export enum GQLErrorCode {
  VALIDATION_FAILED = "VALIDATION_FAILED",
}

export function handleAPIError(
  error: any,
  showErrorToast: ShowErrorCallbackType,
) {
  console.error(error);

  let title = "A problem occurred";
  let text = "Please try again.";

  if (error instanceof ApolloError) {
    const gqlError = error.graphQLErrors[0];
    console.error(gqlError);

    if (gqlError?.extensions?.code === GQLErrorCode.VALIDATION_FAILED) {
      title = gqlError.message;
      text = "Please correct the error and try again.";
    }
  }

  showErrorToast({ title, text });
}

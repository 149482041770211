import { EuiFlexGroup, EuiSpacer } from "@equipmentshare/ds2";
import { Device, TrackerState } from "@fleet-configuration/client";
import { Accordion, DetailColumn } from "@fleet-configuration/components";

import { DeviceKind } from "@/types";
import { getDeviceTypeDisplayName } from "@/utils/helpers";

export type DetailsInfoProps = {
  device: Device;
  isLoadingTrackerState?: boolean;
  trackerBatteryVoltage?: TrackerState["trackerBatteryVoltage"];
};

export const DetailsInfo = ({
  device,
  isLoadingTrackerState = false,
  trackerBatteryVoltage,
}: DetailsInfoProps) => {
  const modelTypeName = getDeviceTypeDisplayName(device.typeName);
  const trackerBatteryVoltageValue = !!trackerBatteryVoltage
    ? `${trackerBatteryVoltage}v`
    : "--";
  return (
    <Accordion title="Details">
      <EuiSpacer size="s" />
      <EuiFlexGroup
        direction="column"
        gutterSize="m"
        style={{ padding: "0 26px" }}
      >
        <EuiFlexGroup>
          <DetailColumn label="Model" value={modelTypeName} />
          <DetailColumn label="Serial" value={device.deviceSerial} />
        </EuiFlexGroup>
        {device.kind === DeviceKind.TRACKER && (
          <EuiFlexGroup>
            <DetailColumn
              isLoading={isLoadingTrackerState}
              label="Battery"
              value={trackerBatteryVoltageValue}
            />
          </EuiFlexGroup>
        )}
      </EuiFlexGroup>
    </Accordion>
  );
};

import { ReactNode } from "react";

import { type StaticImageAsset } from "@/config/staticAssets";

// TODO: check to see if these types become available via the @fleet-configuration-client package once the gql client generator is run against the new endpoint
export enum ASSET_TYPE_ID {
  EQUIPMENT = 1,
  VEHICLE = 2,
  TRAILER = 3,
  ATTACHMENT = 4,
  BUCKET = 5,
  SMALL_TOOL = 6,
}

// Maps the correct color to the status of the device installation status panels
export enum DeviceInstallationStatusColorType {
  COMPLETE = "primary",
  UNDETECTED = "warning",
  UNASSIGNED = "warning",
}

export enum DeviceType {
  Camera = "Camera",
  Tracker = "Tracker",
}

/*
NOTE devices that come from the trackers table that don't have a type id or vendor id  === "UNKNOWN" (i.e. AEMP, bluetooth, etc.)
*/
export enum DeviceKind {
  CAMERA = "CAMERA",
  TRACKER = "TRACKER",
  UNKNOWN = "UNKNOWN",
}

/*
NOTE camera vendor names return as lowercase strings from the BE
*/
export enum DeviceTypeName {
  FLEETCAM = "fleetcam",
  SMARTWITNESS = "smartwitness",
  EQUIPMENTSHARE = "equipmentshare",
  OWLCAM = "owlcam",
  PROVISION = "provision",
}

/*
NOTE that the naming of the roles below corresponds to the naming used by es-track/fleet web app, and _not_ the language used in the database and elsewhere
See https://www.notion.so/equipmentshare/Security-Levels-and-User-Roles-203453c8459b402aa6bd30163d934371 for details
*/
export enum Role {
  ADMIN = 1,
  MANAGER = 2,
  USER = 5,
}

export enum Permission {
  CAN_ACCESS_CAMERA = "canAccessCamera",
  CAN_CREATE_ASSET_FINANCIAL_RECORDS = "canCreateAssetFinancialRecords",
  CAN_GRANT_PERMISSIONS = "canGrantPermissions",
  CAN_READ_ASSET_FINANCIAL_RECORDS = "canReadAssetFinancialRecords",
  HIDE_ASSET_DETAILS = "hideAssetDetails",
  READ_ONLY = "readOnly",
  CAN_RENT = "canRent",
}

export type TrackerInstallationGuideMap = {
  description: string;
  guideUrl: string;
  imgData: StaticImageAsset;
  isOwned: boolean;
  model: string;
};

export enum TrackerInstallationGuideModel {
  FJ2500 = "FJ2500",
  GL520 = "GL500/520",
  MC4 = "MC4+/MC4++",
  MCX101 = "MCX101",
  T3_DASH_CAM = "T3 Dash Cam",
}

export type TrackerInstallationGuide = {
  description: string;
  guideUrl: string;
  image: StaticImageAsset;
  isOwned: boolean;
  name: string;
};

export type TrackerInstallationGuideListItem = Omit<
  TrackerInstallationGuide,
  "image"
> & {
  image: ReactNode;
  onGuideView?: () => void;
};

import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFormRow,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@equipmentshare/ds2";
import {
  AlertLineIcon,
  InformationLineIcon,
  LifebuoyLineIcon,
} from "@equipmentshare/ds2-icons";
import {
  EXTERNAL_URLS,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { CONSTANTS } from "@/config/constants";
import { DeviceType } from "@/types";

export const VinCallout = () => (
  <EuiFormRow fullWidth>
    <EuiCallOut
      data-testid="vin-callout"
      iconType={InformationLineIcon}
      title="Enter VIN to populate asset details in next step."
    />
  </EuiFormRow>
);

type VinErrorCalloutProps = {
  onContinue: () => void;
  onUpdate: () => void;
};

type VinAPIErrorCalloutProps = {
  onContinue: () => void;
};

export const VinErrorCallout = ({
  onContinue,
  onUpdate,
}: VinErrorCalloutProps) => (
  <EuiFormRow fullWidth>
    <EuiCallOut
      color="danger"
      data-testid="vin-error-callout"
      iconType={AlertLineIcon}
      title="VIN Errors Found"
    >
      <EuiText>
        The VIN entered does not match formatting requirements and cannot be
        validated. Do you want to update the VIN or continue to the next step?
      </EuiText>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiButton color="danger" onClick={onUpdate} size="s">
          Update VIN
        </EuiButton>
        <EuiButton color="danger" fill onClick={onContinue} size="s">
          Continue
        </EuiButton>
      </EuiFlexGroup>
    </EuiCallOut>
  </EuiFormRow>
);

export const VinAPIErrorCallout = ({ onContinue }: VinAPIErrorCalloutProps) => (
  <EuiFormRow fullWidth>
    <EuiCallOut
      color="warning"
      data-testid="vin-api-error-callout"
      title="VIN validation is currently unavailable"
    >
      <EuiText>You will need to add asset details manually.</EuiText>
      <EuiSpacer size="m" />
      <EuiButton
        color="warning"
        data-testid="vin-api-error-continue"
        fill
        onClick={onContinue}
        size="s"
      >
        Continue
      </EuiButton>
    </EuiCallOut>
  </EuiFormRow>
);

export const AdditionalDetailsCallout = () => (
  <EuiFormRow fullWidth>
    <EuiCallOut
      data-testid="additional-details-callout"
      iconType={InformationLineIcon}
      size="s"
      title="Want to add additional details?"
    >
      <p>
        Once an asset is saved you can add and edit asset details any time using
        the asset&apos;s edit tab.{" "}
        <EuiLink
          external
          href={EXTERNAL_URLS.T3_ADDITIONAL_DETAILS_HELP}
          target="_blank"
        >
          Learn more.
        </EuiLink>
      </p>
    </EuiCallOut>
  </EuiFormRow>
);

// ASSIGN DEVICES

export const NoTrackersCallout = () => (
  <EuiFormRow fullWidth>
    <EuiCallOut
      color="success"
      css={{ padding: "1rem" }}
      data-testid="no-trackers-callout"
      iconType={"check"}
      size="s"
      title="All trackers assigned"
    >
      <EuiText size="s">
        <p>All trackers have been assigned to assets.</p>
      </EuiText>
    </EuiCallOut>
  </EuiFormRow>
);

export const NoCamerasCallout = () => (
  <EuiFormRow fullWidth>
    <EuiCallOut
      color="success"
      css={{ padding: "1rem" }}
      data-testid="no-cameras-callout"
      iconType={"check"}
      size="s"
      title="All cameras assigned"
    >
      <EuiText size="s">
        <p>All cameras have been assigned to assets.</p>
      </EuiText>
    </EuiCallOut>
  </EuiFormRow>
);

type NoAssetsCalloutProps = {
  deviceType: DeviceType;
};

export const NoAssetsCallout = ({ deviceType }: NoAssetsCalloutProps) => {
  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });

  return (
    <EuiFormRow fullWidth>
      <EuiCallOut
        color="warning"
        css={{ padding: "1rem" }}
        data-testid={`no-assets-callout-${deviceType}`}
        iconType={LifebuoyLineIcon}
        size="s"
        title="No unassigned assets available"
      >
        <EuiText size="s">
          <p>
            All available assets have been assigned. Create a new asset from the{" "}
            <EuiLink
              aria-label="link to assets page in T3"
              onClick={() =>
                sendMessageToParentWindow(T3_PATHNAME.COMPANY_ASSETS)
              }
            >
              assets page
            </EuiLink>
            , or manage assignment in the edit tab for each asset.
          </p>
        </EuiText>
      </EuiCallOut>
    </EuiFormRow>
  );
};

import { COLORS } from "@fleet-configuration/utils";
import { ReactNode } from "react";

import { DeviceInstallStatusItem } from "@/hooks/useDeviceInstallStatus";
import { DeviceInstallStatusEnum } from "@/types/gql";

export type AggregateDeviceInstallStatusArgs = {
  data: DeviceInstallStatusItem[];
  facet: DeviceInstallStatusEnum[];
  aggregateColor?: string;
  aggregateName?: string;
  aggregateDetail?: ReactNode;
  valueOverride?: number;
};

const getDefaultAggregate = ({
  aggregateName,
  aggregateColor,
  aggregateDetail,
}: AggregateDeviceInstallStatusArgs) => ({
  color: aggregateColor ?? COLORS.DEVICE_INSTALL_STATUS.STATUS_DEFAULT,
  detail: aggregateDetail,
  formattedValue: "0",
  id: aggregateName ?? ("Other" as DeviceInstallStatusEnum),
  label: aggregateName ?? ("Other" as DeviceInstallStatusEnum),
  value: 0,
});

export const aggregateDeviceInstallStatus = (
  args: AggregateDeviceInstallStatusArgs,
) => {
  const defaultAggregate = getDefaultAggregate(args);

  const aggregateDeviceInstallStatus = args.data.reduce((acc, item) => {
    if (args.facet.includes(item.id)) {
      const updatedValue = args.valueOverride
        ? args.valueOverride
        : acc.value + item.value;
      acc = {
        ...acc,
        color: args.aggregateColor ?? defaultAggregate.color,
        detail: args.aggregateDetail ?? defaultAggregate.detail,
        formattedValue: updatedValue.toLocaleString(),
        id: args.aggregateName ?? defaultAggregate.id,
        label: args.aggregateName ?? defaultAggregate.label,
        value: updatedValue,
      };
    }
    return acc;
  }, defaultAggregate);

  return aggregateDeviceInstallStatus;
};

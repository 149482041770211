"use client";

import { useCreateUserMutation } from "@fleet-configuration/client";
import {
  FlyoutForm,
  useFlyout,
  useToastContext,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";
import { SubmitHandler, useForm } from "react-hook-form";

import { AddUserFormFields, AddUsersForm } from "@/components/forms/add-users";
import { ActionCard } from "@/components/get-started/action-cards/action-card";
import { GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";
import { GQL, Role } from "@/types";
import { handleAPIError } from "@/utils/validation";

type AddUsersCardProps = GetStartedCardProps;

export const AddUsersCard = ({
  handleCloseAction = () => {},
  state,
}: AddUsersCardProps) => {
  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });

  const { isFlyoutVisible, openFlyout, closeFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.ADD_USERS,
  );

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: Role.USER,
      permissions: {
        readOnly: false,
        hideAssetDetails: false,
        canRent: false,
        canGrantPermissions: undefined,
        canReadAssetFinancialRecords: undefined,
        canCreateAssetFinancialRecords: undefined,
        canAccessCamera: undefined,
      },
    } as AddUserFormFields,
  });

  const [createUser] = useCreateUserMutation();

  const { showToastWithAction, showErrorToast } = useToastContext();

  async function sendSaveUserRequest(data: AddUserFormFields) {
    const input: GQL.CreateUser = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roleId: parseInt(data.role as any as string),
      permissions: {
        readOnly: data.permissions.readOnly,
        hideAssetDetails: data.permissions.hideAssetDetails,
        canRent: data.permissions.canRent,
        canGrantPermissions: data.permissions.canGrantPermissions,
        canReadAssetFinancialRecords:
          data.permissions.canReadAssetFinancialRecords,
        canCreateAssetFinancialRecords:
          data.permissions.canCreateAssetFinancialRecords,
        canAccessCamera: data.permissions.canAccessCamera,
      },
    };

    await createUser({ variables: { input } });
  }

  const onSubmit: SubmitHandler<AddUserFormFields> = async (data) => {
    await sendSaveUserRequest(data);

    showToastWithAction({
      action: () => {
        sendMessageToParentWindow(T3_PATHNAME.USERS);
      },
      actionText: "View all users",
      text: `${data.firstName} ${data.lastName} was added as a user`,
      title: "New User Added!",
    });
  };

  const trackSave = (
    data: AddUserFormFields,
    saveType: "save" | "saveAndAddAnother",
  ) => {
    heaperEvent(
      "Fleet Configuration Dashboard - Get Started - Click - Complete Add Users Step",
      {
        role_assigned: data.role === Role.MANAGER ? "manager" : "user",
        permissions_granted: Object.entries(data.permissions)
          .filter(([permission, granted]) => granted)
          .map(([permission, _]) => permission)
          .join(","),
        save_type: saveType,
      },
    );
  };

  const handleSave = async () => {
    await handleSubmit(async (data) => {
      try {
        await onSubmit(data);
        trackSave(data, "save");
      } catch (e: any) {
        handleAPIError(e, showErrorToast);
        // return early to keep form open
        return;
      }
      handleCloseFlyout();
    })();
  };

  const handleSaveAndContinue = async () => {
    await handleSubmit(async (data) => {
      try {
        await onSubmit(data);
        trackSave(data, "saveAndAddAnother");
      } catch (e: any) {
        handleAPIError(e, showErrorToast);
        return;
      }
      resetForm();
      handleCloseAction();
    })();
  };

  const handleCloseFlyout = () => {
    handleCloseAction();
    closeFlyout();
    resetForm();
  };

  const actionTitle = "Add Users";
  return (
    <>
      <ActionCard
        handleAction={() => {
          heaperEvent(
            "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
            { step: "Add Users", source: "getStarted" },
          );
          openFlyout();
        }}
        state={state}
        subtitle="Set up users and permissions to begin collaborating together in T3."
        title={actionTitle}
      />
      <FlyoutForm
        flyoutBody={
          <AddUsersForm
            control={control}
            onSubmit={() => handleSubmit(onSubmit)}
          />
        }
        handleClose={handleCloseFlyout}
        hasRequiredFields
        headerText={actionTitle}
        isVisible={isFlyoutVisible}
        primaryAction={{ action: handleSave }}
        secondaryAction={{
          action: handleSaveAndContinue,
        }}
        tertiaryAction={{ action: handleCloseFlyout }}
      />
    </>
  );
};

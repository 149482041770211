import { Device } from "@fleet-configuration/client";
import { TRACKER_INSTALL_GUIDE } from "@fleet-configuration/utils";

export const deviceGuides = {
  FJ2500LA: TRACKER_INSTALL_GUIDE.FJ2500,
  FJ2500LS: TRACKER_INSTALL_GUIDE.FJ2500,
  fleetcam: TRACKER_INSTALL_GUIDE.T3_DASH_CAM,
  GL500M: TRACKER_INSTALL_GUIDE.GL520,
  GL520M: TRACKER_INSTALL_GUIDE.GL520,
  "MC-4+": TRACKER_INSTALL_GUIDE.MC4_PLUS,
  "MC-4++": TRACKER_INSTALL_GUIDE.MC4_PLUS,
  "MCX 101": TRACKER_INSTALL_GUIDE.MCX101,
  "Slap-n-Track": undefined,
};

export const getDeviceInstallationGuideUrl = (
  deviceTypeName: Device["typeName"],
) => {
  if (!deviceTypeName) {
    return null;
  }

  return deviceGuides[deviceTypeName as keyof typeof deviceGuides];
};

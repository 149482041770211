import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@equipmentshare/ds2";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { COLORS, LIGHT_BORDER } from "@fleet-configuration/utils";

import { TrackerInstallationGuideListItem } from "@/types";

export const TrackerInstallListItem = (
  props: TrackerInstallationGuideListItem,
) => {
  const handleGuideView = () => {
    (props.onGuideView && props.onGuideView()) || null;
    heaperEvent(
      "Fleet Configuration Dashboard - Install Trackers - Click - View Install Guide",
      {
        device_name: props.name,
        is_owned: props.isOwned,
      },
    );
  };

  return (
    <EuiPanel
      borderRadius="none"
      data-testid={`tracker-${props.name}`}
      grow={true}
      hasShadow={false}
      paddingSize="m"
      style={{ borderBottom: `${LIGHT_BORDER}` }}
    >
      <EuiFlexGroup alignItems="flexStart" gutterSize="none" responsive={false}>
        {props.image}
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h2>{props.name}</h2>
          </EuiTitle>
          <EuiSpacer size="xs" />
          <EuiText size="s">
            <p>{props.description}</p>
          </EuiText>
          {props.isOwned && (
            <div data-testid="owned-device-badge">
              <EuiSpacer size="xs" />
              <EuiFlexGroup
                alignItems="center"
                direction="row"
                gutterSize="s"
                responsive={false}
              >
                <EuiBadge
                  color={COLORS.SUCCESS}
                  style={{
                    padding: "0",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <EuiFlexItem grow={false}>
                  <EuiText color="subdued" size="s">
                    <strong>Owned Device</strong>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          )}
          <EuiSpacer size="xs" />
          <EuiLink
            data-testid={`view-installation-guide-tracker-${props.name}`}
            href={props.guideUrl}
            onClick={handleGuideView}
            target="_blank"
          >
            View installation guide
          </EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

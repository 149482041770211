import {
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiSelectOption,
  EuiSpacer,
  EuiText,
  EuiTextColor,
} from "@equipmentshare/ds2";
import {
  CLIENT_CONSTANTS,
  useSearchMakesQuery,
  useSearchModelsQuery,
} from "@fleet-configuration/client";
import {
  ComboBoxField,
  FormRowLabel,
  Select,
  TextField,
  YearField,
} from "@fleet-configuration/components";
import { debounce } from "@fleet-configuration/utils";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext, UseFormWatch } from "react-hook-form";

import {
  AdditionalDetailsCallout,
  VinAPIErrorCallout,
  VinCallout,
  VinErrorCallout,
} from "@/components/forms/callouts";
import { FormStep } from "@/components/get-started/types";
import { ASSET_TYPE_ID } from "@/types";

export type AddAssetFormFields = {
  assetTypeId: number | undefined;
  customModel: string;
  customName: string;
  make: number | undefined;
  model: number | undefined;
  serial: string;
  vin: string;
  year: string;
};

export type PresetsFromVin = {
  makeId?: number | null;
  makeName?: string | null;
  modelId?: number | null;
  year?: string | null;
  hasValidationErrors?: boolean;
  hasApiErrors?: boolean;
};

type AddAssetsFormProps = {
  onSubmit: () => void;
  presetsFromVin?: PresetsFromVin;
  step: FormStep;
  setStep: (step: FormStep) => void;
  watch: UseFormWatch<AddAssetFormFields>;
  handleUpdateVin: () => void;
  handleContinueWithVinApiError: () => void;
};

const assetTypeEmptyFirstOption: EuiSelectOption = {
  value: "",
  text: "Select an asset type",
  disabled: true,
};

const assetTypeOptions = [
  assetTypeEmptyFirstOption,
  {
    value: ASSET_TYPE_ID.EQUIPMENT,
    text: "Equipment",
  },
  {
    value: ASSET_TYPE_ID.VEHICLE,
    text: "Vehicle",
  },
  {
    value: ASSET_TYPE_ID.TRAILER,
    text: "Trailer",
  },
  {
    value: ASSET_TYPE_ID.ATTACHMENT,
    text: "Attachment",
  },
  {
    value: ASSET_TYPE_ID.BUCKET,
    text: "Bucket",
  },
  {
    value: ASSET_TYPE_ID.SMALL_TOOL,
    text: "Small Tool",
  },
];

type AssetDetailsProps = {
  assetTypeName?: string;
  vin?: string;
};

export const AssetDetails = ({ assetTypeName, vin }: AssetDetailsProps) => (
  <>
    <EuiText>
      <h3>Asset Details</h3>
    </EuiText>
    <EuiSpacer size="m" />
    <EuiFlexGroup>
      {assetTypeName && (
        <EuiFlexItem>
          <EuiFormRow label={<FormRowLabel label="Asset Type" />}>
            <EuiText data-testid="asset-type-display">
              <p>{assetTypeName}</p>
            </EuiText>
          </EuiFormRow>
        </EuiFlexItem>
      )}
      {vin && (
        <EuiFlexItem>
          <EuiFormRow label={<FormRowLabel label="VIN" />}>
            <EuiText data-testid="vin-display">
              <p>{vin}</p>
            </EuiText>
          </EuiFormRow>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  </>
);

export const AddAssetsForm = ({
  handleUpdateVin,
  handleContinueWithVinApiError,
  onSubmit,
  watch,
  step,
  setStep,
  presetsFromVin,
}: AddAssetsFormProps) => {
  const [showCustomModelInput, setShowCustomModelInput] = useState(false);
  const [isLoadingMakes, setIsLoadingMakes] = useState(false);
  const [isLoadingModels, setIsLoadingModels] = useState(false);

  const { fetchMore: fetchMoreMakes } = useSearchMakesQuery({
    skip: true,
  });

  const [makesOptions, setMakesOptions] = useState<EuiComboBoxOptionOption[]>(
    [],
  );

  const { fetchMore: fetchMoreModels } = useSearchModelsQuery({
    skip: true,
  });

  const [modelsOptions, setModelsOptions] = useState<EuiComboBoxOptionOption[]>(
    [],
  );

  const { control, getValues, resetField, setValue } = useFormContext();
  const makeId = watch("make");
  const assetTypeId = watch("assetTypeId");
  const vin = getValues("vin");
  const selectedAssetTypeName = assetTypeOptions.find(
    (assetType) => assetType.value === getValues("assetTypeId"),
  )?.text;

  const debouncedMakeSearch = debounce(async (searchQuery: string) => {
    setIsLoadingMakes(true);
    try {
      const results = await fetchMoreMakes({
        variables: {
          input: {
            query: searchQuery,
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
        },
      });
      setMakesOptions(
        results.data?.searchMakes.items.map((make) => ({
          value: make.makeId,
          label: make.name,
        })) as EuiComboBoxOptionOption[],
      );
    } catch (error) {
      console.error("Failed to fetch makes:", error);
    } finally {
      setIsLoadingMakes(false);
    }
  });

  const debouncedModelSearch = debounce(async (searchQuery: string) => {
    setIsLoadingModels(true);
    const results = await fetchMoreModels({
      variables: {
        input: {
          query: searchQuery,
          ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
        },
        makeId: Number(makeId),
      },
    });
    setIsLoadingModels(false);
    setModelsOptions(
      results.data?.searchModels.items.map((model) => ({
        value: model.modelId,
        label: model.name,
      })) as EuiComboBoxOptionOption[],
    );
  });

  const setInitialModelOptions = async () => {
    setIsLoadingModels(true);
    const results = await fetchMoreModels({
      variables: {
        input: {
          query: "",
          ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
        },
        makeId: makeId,
      },
    });

    setIsLoadingModels(false);
    setModelsOptions(
      results.data?.searchModels.items.map((model) => ({
        value: model.modelId,
        label: model.name,
      })) as EuiComboBoxOptionOption[],
    );

    presetsFromVin?.modelId &&
      setValue("model", presetsFromVin?.modelId, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
  };

  const setInitialMakeOptions = useCallback(
    async (makeName: string = "") => {
      setIsLoadingMakes(true);
      const { data } = await fetchMoreMakes({
        variables: {
          input: {
            query: makeName,
            ...CLIENT_CONSTANTS.DEFAULT_SEARCH_QUERY_OPTIONS,
          },
          filter: { assetTypeId },
        },
      });
      setIsLoadingMakes(false);
      setMakesOptions(
        data.searchMakes.items.map((make) => ({
          value: make.makeId,
          label: make.name,
        })) as EuiComboBoxOptionOption[],
      );
    },
    [assetTypeId, fetchMoreMakes],
  );

  useEffect(() => {
    if (assetTypeId) {
      void setInitialMakeOptions(presetsFromVin?.makeName ?? "");
    }
  }, [assetTypeId, setInitialMakeOptions, presetsFromVin]);

  useEffect(() => {
    presetsFromVin?.makeId &&
      setValue("make", presetsFromVin.makeId, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

    presetsFromVin?.year &&
      setValue("year", presetsFromVin.year, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
  }, [presetsFromVin, setValue]);

  useEffect(() => {
    resetField("model");
    if (makeId) {
      setInitialModelOptions();
    } else {
      setModelsOptions([]);
    }
    // Excluding 'setInitialModelOptions' from dependencies array to prevent unnecessary re-renders
  }, [makeId, setModelsOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EuiForm data-testid={`add-assets-form-step-${step}`} onSubmit={onSubmit}>
      {step === FormStep.AssetTypeStep && (
        <>
          <Controller
            control={control}
            name="assetTypeId"
            render={({
              field: { value, onChange },
              fieldState: { error, invalid },
            }) => {
              return (
                <Select
                  castValueToNumber
                  errorMessage={error?.message}
                  isDisabled={presetsFromVin?.hasValidationErrors}
                  isInvalid={invalid}
                  isRequired
                  label="Asset Type"
                  onChange={onChange}
                  options={assetTypeOptions}
                  testId="asset-type-select"
                  value={value}
                />
              );
            }}
            rules={{
              required: { value: true, message: "Asset type is required." },
            }}
          />
          {[ASSET_TYPE_ID.VEHICLE, ASSET_TYPE_ID.TRAILER].includes(
            assetTypeId as ASSET_TYPE_ID,
          ) && (
            <>
              <Controller
                control={control}
                name="vin"
                render={({
                  field: { value, onChange, ref },
                  fieldState: { invalid, error, isTouched },
                }) => {
                  return (
                    <TextField
                      errorMessage={error?.message || "VIN is invalid"}
                      inputRef={ref}
                      isDisabled={presetsFromVin?.hasValidationErrors}
                      isInvalid={
                        presetsFromVin?.hasValidationErrors ||
                        (invalid && isTouched)
                      }
                      label="VIN"
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
                rules={{
                  minLength: {
                    value: 17,
                    message: "VIN must be 17 characters long",
                  },
                  maxLength: {
                    value: 17,
                    message: "VIN must be 17 characters long",
                  },
                }}
              />
              {presetsFromVin?.hasApiErrors && (
                <VinAPIErrorCallout
                  onContinue={() => {
                    handleContinueWithVinApiError();
                    setStep(FormStep.AssetDetailsStep);
                  }}
                />
              )}
              {presetsFromVin?.hasValidationErrors && (
                <VinErrorCallout
                  onContinue={() => setStep(FormStep.AssetDetailsStep)}
                  onUpdate={handleUpdateVin}
                />
              )}
              {!presetsFromVin?.hasValidationErrors &&
                !presetsFromVin?.hasApiErrors && <VinCallout />}
            </>
          )}
        </>
      )}

      {step === FormStep.AssetDetailsStep && (
        <>
          <AssetDetails
            assetTypeName={String(selectedAssetTypeName)}
            vin={vin}
          />
          <EuiSpacer size="m" />
          <Controller
            control={control}
            name="customName"
            render={({ field: { value, onChange } }) => {
              return (
                <TextField
                  label="Asset Name"
                  onChange={onChange}
                  testId="asset-name-input"
                  value={value}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="make"
            render={({ field: { value, onChange } }) => {
              return (
                <ComboBoxField
                  isLoading={isLoadingMakes}
                  label="Make"
                  onChange={(selectedMake) => {
                    if (!selectedMake) {
                      setInitialMakeOptions();
                    }
                    onChange(selectedMake);
                  }}
                  onSearchChange={debouncedMakeSearch}
                  options={makesOptions}
                  value={value}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="model"
            render={({ field: { value, onChange } }) => {
              return (
                <ComboBoxField
                  isDisabled={!makeId}
                  isLoading={isLoadingModels}
                  label="Model"
                  onChange={onChange}
                  onSearchChange={debouncedModelSearch}
                  options={modelsOptions}
                  placeholder={
                    makeId
                      ? ""
                      : "Please choose a make before selecting a model"
                  }
                  value={value}
                />
              );
            }}
          />
          {showCustomModelInput && (
            <Controller
              control={control}
              name="customModel"
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    label="Custom Model"
                    labelAppend={
                      <EuiLink disabled>
                        <EuiTextColor color="#ABB4C4">
                          Add Custom Model
                        </EuiTextColor>
                      </EuiLink>
                    }
                    onChange={onChange}
                    onHoverInfoText="Set a custom model name if the asset model is not listed"
                    value={value}
                  />
                );
              }}
            />
          )}
          <Controller
            control={control}
            name="year"
            render={({ field: { value, onChange } }) => {
              return (
                <YearField
                  label="Year"
                  labelAppend={
                    showCustomModelInput ? undefined : (
                      <EuiLink onClick={() => setShowCustomModelInput(true)}>
                        Add Custom Model
                      </EuiLink>
                    )
                  }
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
          {assetTypeId !== ASSET_TYPE_ID.VEHICLE && (
            <Controller
              control={control}
              name="serial"
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    label="Serial Number"
                    onChange={onChange}
                    testId="serial-input"
                    value={value}
                  />
                );
              }}
            />
          )}
          <AdditionalDetailsCallout />
        </>
      )}
    </EuiForm>
  );
};

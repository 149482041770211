import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiPanelProps,
  EuiSkeletonText,
  EuiSkeletonTextProps,
  EuiSpacer,
  EuiText,
  useIsWithinBreakpoints,
} from "@equipmentshare/ds2";
import { kebabCase } from "lodash";
import { ReactNode } from "react";

import { CONSTANTS } from "@/config/constants";

export interface WidgetWrapperProps {
  children: React.ReactNode;
  title: string;
  color?: EuiPanelProps["color"];
  extraAction?: React.ReactNode;
  style?: EuiPanelProps["style"];
  subTitle?: ReactNode | string;
  subTitleLoading?: boolean;
  subTitleLines?: EuiSkeletonTextProps["lines"];
  testId?: string;
}

export const WidgetWrapper = (props: WidgetWrapperProps) => {
  const testId = props.testId || kebabCase(props.title);
  const subTitleLoadingTestId = `${testId}-sub-title-skeleton`;
  const subTitleTestId = `${testId}-sub-title`;

  const renderHeader = () =>
    props.extraAction ? renderTitleWithExtraAction() : renderTitle();

  const renderTitle = () => (
    <EuiText size="s">
      <h2>{props.title}</h2>
    </EuiText>
  );

  const renderTitleWithExtraAction = () => {
    return (
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>{renderTitle()}</EuiFlexItem>
        <EuiFlexItem grow={false}>{props.extraAction}</EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const mediaStyles = useIsWithinBreakpoints(
    CONSTANTS.LAYOUT.IS_MULTI_COLUMN_LAYOUT,
  )
    ? { maxHeight: `${CONSTANTS.LAYOUT.WIDGET_MAX_HEIGHT}` }
    : undefined;

  return (
    <EuiPanel
      color={props.color}
      data-testid={testId}
      hasBorder
      hasShadow={false}
      paddingSize="m"
      style={{ ...mediaStyles, ...props.style }}
    >
      {renderHeader()}
      <EuiSpacer size="xs" />
      {props.subTitle && (
        <EuiSkeletonText
          data-testid={subTitleLoadingTestId}
          isLoading={props.subTitleLoading || false}
          lines={props.subTitleLines || 1}
        >
          <EuiText color="subdued" size="s">
            <p data-testid={subTitleTestId}>{props.subTitle}</p>
          </EuiText>
        </EuiSkeletonText>
      )}
      <EuiSpacer size="m" />
      {props.children}
    </EuiPanel>
  );
};

import { Asset, Device } from "@fleet-configuration/client";

import { DeviceTypeName } from "@/types";

// sorts alphanumerically by the given key without worrying about upper vs lower case
export const sortAlphanumericallyBy =
  (key: string) => (a: { [key: string]: any }, b: { [key: string]: any }) =>
    a[key].toLowerCase() > b[key].toLowerCase()
      ? 1
      : b[key].toLowerCase() > a[key].toLowerCase()
        ? -1
        : 0;

export const getAssetMakeModel = (asset: Asset): string => {
  if (asset.make?.name && asset.model?.name) {
    return `${asset.make.name} ${asset.model.name}`;
  }
  if (asset.make?.name || asset.model?.name) {
    return (asset.make?.name || asset.model?.name) ?? "";
  }
  return "";
};

export const getMakeModelSerialVin = (asset: Asset): string => {
  const makeModel = getAssetMakeModel(asset);
  const vinOrSerial = (asset.vin || asset.serialNumber) ?? "";

  return [makeModel, vinOrSerial].filter(Boolean).join(" - ");
};

// maps the device type name to a more user-friendly name
export const getDeviceTypeDisplayName = (
  deviceTypeName: Device["typeName"],
) => {
  switch (deviceTypeName) {
    case DeviceTypeName.FLEETCAM:
      return "T3 Dash Cam";
    case DeviceTypeName.SMARTWITNESS:
      return "SmartWitness";
    case DeviceTypeName.EQUIPMENTSHARE:
      return "EquipmentShare";
    case DeviceTypeName.OWLCAM:
      return "Owlcam";
    case DeviceTypeName.PROVISION:
      return "Pro-Vision";
    default:
      return deviceTypeName ?? "Unknown";
  }
};

export const formattedDate = (isoDate: string): string => {
  const date = new Date(isoDate);

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "numeric",
    day: "2-digit",
  };
  const shortDate = date.toLocaleDateString("en-US", dateOptions); // confirm if this is the correct locale

  return shortDate;
};

export const formattedTimeAndDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  let time = date.toLocaleTimeString("en-US", timeOptions);
  time = time.replace(" AM", "am").replace(" PM", "pm");

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "numeric",
    day: "2-digit",
  };
  const day = date.toLocaleDateString("en-US", dateOptions);

  return `${time} - ${day}`;
};

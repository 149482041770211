import { FlyoutForm, useFlyout } from "@fleet-configuration/components";

import { YourDevicesList } from "@/components/device-installation-progress/your-devices-flyout/your-devices-list";
import { CONSTANTS } from "@/config/constants";

export const YourDevicesFlyout = () => {
  const { isFlyoutVisible, closeFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.YOUR_DEVICES,
  );

  const { closeFlyout: closeFiltersFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.FILTERS,
  );

  const { closeFlyout: closeAssignDevicesFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.ASSIGN_DEVICES,
  );

  return (
    <FlyoutForm
      flyoutBody={<YourDevicesList />}
      handleClose={() => {
        // attempt to close all flyouts that can be opened on top of this flyout.
        // this is a workaround for a bug in EUI where "Esc" key press does not propagate to all flyouts, only the first one.
        closeFlyout();
        closeFiltersFlyout();
        closeAssignDevicesFlyout();
      }}
      headerText="Your Devices"
      isVisible={isFlyoutVisible}
      subHeader="Search for a device, manage its install status and find helpful resources."
    />
  );
};

import { EuiText } from "@equipmentshare/ds2";
import {
  Device,
  useConfirmDeviceInstalledMutation,
} from "@fleet-configuration/client";
import {
  ModalWizard,
  useModalWizardState,
  WizardStepConfig,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";

import { CONSTANTS } from "@/config/constants";
import { getDeviceInstallationGuideUrl } from "@/config/deviceInstallationGuides";

enum StepTitle {
  DEVICE_PHYSICALLY_INSTALLED = "Device Physically Installed?",
  HELP_WITH_DETECTION = "Help with Detection",
  WE_ARE_HERE_TO_HELP = "We're here to help!",
}

export enum TriggerSource {
  DEVICE_DETAILS = "DEVICE_DETAILS",
  DEVICE_LIST = "DEVICE_LIST",
}

export const useInstallHelpWizard = (device: Device, source: TriggerSource) => {
  const [confirmDeviceInstalled] = useConfirmDeviceInstalledMutation({
    variables: { deviceId: device.deviceId },
    ignoreResults: true,
  });

  const deviceGuideUrl = getDeviceInstallationGuideUrl(device.typeName!);

  const {
    showModal,
    openModal,
    closeModal,
    currentStepIndex,
    setCurrentStepIndex,
  } = useModalWizardState();

  const { notifyParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES
      .T3_IFRAME_TRIGGERED_INTERCOM_MESSAGE,
  });

  const onClose = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Install Help Wizard - Click - Close",
      {
        device_name: device.typeName!,
        step: steps[currentStepIndex].title as StepTitle,
      },
    );
    setCurrentStepIndex(0);
    closeModal();
  };

  const onOpen = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Install Progress - Click - Install Help",
      { device_name: device.typeName!, source },
    );
    openModal();
  };

  const contactSupport = (is_installed: boolean) => {
    heaperEvent(
      "Fleet Configuration Dashboard - Install Help Wizard - Click - Contact Support",
      { device_name: device.typeName!, is_installed },
    );
    notifyParentWindow();
    onClose();
  };

  const openGuide = () => {
    if (!deviceGuideUrl) {
      return;
    }
    heaperEvent(
      "Fleet Configuration Dashboard - Install Help Wizard - Click - View Installation Guide",
      { device_name: device.typeName! },
    );
    window.open(deviceGuideUrl, "_blank");
    onClose();
  };

  const steps: WizardStepConfig[] = [
    {
      title: StepTitle.DEVICE_PHYSICALLY_INSTALLED,
      body: `Is your device physically installed in an asset?`,
      primaryAction: {
        label: "Yes",
        action: () => {
          heaperEvent(
            "Fleet Configuration Dashboard - Install Help Wizard - Click - Is Device Installed",
            { is_installed: true, device_name: device.typeName! },
          );
          setCurrentStepIndex(1);
          confirmDeviceInstalled();
        },
      },
      secondaryAction: {
        label: "No",
        action: () => {
          heaperEvent(
            "Fleet Configuration Dashboard - Install Help Wizard - Click - Is Device Installed",
            { is_installed: false, device_name: device.typeName! },
          );
          setCurrentStepIndex(2);
        },
      },
    },
    {
      title: StepTitle.HELP_WITH_DETECTION,
      body: (
        <EuiText>
          <p>
            Check for these common issues that could be keeping your device from
            being detected. Our support team is also available to help.
          </p>
          <ol>
            {[
              "Make sure the asset has a clear view of the sky.",
              "Be sure GPS/Cell reception is available at the device's location.",
              "Drive the asset or engage high idle for 15 minutes to allow more information to populate to the device.",
              "Ignition cycle the asset 2-3 times over 10 minutes. Key switch: Off>On>Start then let run for 3 minutes before turning off. Repeat 2 to 3 cycles.",
            ].map((troubleshootingStep) => (
              <li className="mb-3" key={troubleshootingStep}>
                {troubleshootingStep}
              </li>
            ))}
          </ol>
        </EuiText>
      ),
      primaryAction: {
        label: "Contact Support",
        action: () => contactSupport(true),
      },
    },
    {
      title: StepTitle.WE_ARE_HERE_TO_HELP,
      body: `Contact our support team to get guided help.${deviceGuideUrl ? " If you haven't received our helpful install guide, you can download it below." : ""}`,
      primaryAction: {
        label: "Contact Support",
        action: () => contactSupport(false),
      },
      ...(deviceGuideUrl && {
        secondaryAction: {
          label: "View Installation Guide",
          action: openGuide,
        },
      }),
    },
  ];

  const ModalComponent = () => (
    <ModalWizard
      currentStepIndex={currentStepIndex}
      onClose={onClose}
      showModal={showModal}
      steps={steps}
    />
  );

  return { ModalComponent, openModal: onOpen };
};

"use client";

import {
  DataVizBaseDatum,
  type DataVizContainerDimensionsDefaults,
  Pie,
} from "@fleet-configuration/components";
import millify from "millify";

export type PieChartProps = {
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: DataVizBaseDatum[];
  showArcLabels?: boolean;
};

export const PieChart = (props: PieChartProps) => {
  return (
    <Pie
      arcLabels={{
        color: { from: "color", modifiers: [["brighter", 3]] },
        showLabels: props.showArcLabels ?? true,
        skipAngle: 10,
      }}
      containerDimensions={props.containerDimensions}
      data={props.data}
      modifiers={{
        arcLabelRenderer: (e) => millify(Number(e)),
      }}
      showTooltip
      testId="assets-pie-chart"
    />
  );
};

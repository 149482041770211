"use client";

import {
  Asset,
  useCreateAssetMutation,
  useGetDetailsFromVinQuery,
  useSearchAssetsEsApiQuery,
} from "@fleet-configuration/client";
import {
  FlyoutForm,
  useFlyout,
  useToastContext,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  replaceStringPart,
  T3_PATHNAME,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
  AddAssetFormFields,
  AddAssetsForm,
  PresetsFromVin,
} from "@/components/forms/add-assets";
import { ActionCard } from "@/components/get-started/action-cards/action-card";
import {
  FieldInUseModal,
  FieldName,
} from "@/components/get-started/action-cards/quick-add-assets/field-in-use-modal";
import { FormStep, GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";
import { handleAPIError } from "@/utils/validation";

type QuickAddAssetsCardProps = GetStartedCardProps;

enum SaveType {
  SAVE = "save",
  SAVE_AND_ADD_ANOTHER = "saveAndAddAnother",
}

enum SearchField {
  VIN = "vin",
  SERIAL = "serial_number",
}

export const QuickAddAssetsCard = ({
  handleCloseAction = () => {},
  state,
}: QuickAddAssetsCardProps) => {
  const { sendMessageToParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES.T3_IFRAME_TRIGGERED_ROUTE,
  });
  const { closeFlyout, isFlyoutVisible, openFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.CREATE_ASSET,
  );

  const [isCheckingVin, setIsCheckingVin] = useState(false);
  const [currentStep, setStep] = useState<FormStep>(FormStep.AssetTypeStep);
  const [assetsWithDuplicateVin, setAssetsWithDuplicateVin] = useState<Asset[]>(
    [],
  );
  const [assetsWithDuplicateSerial, setAssetsWithDuplicateSerial] = useState<
    Asset[]
  >([]);
  const [pendingSaveAction, setPendingSaveAction] = useState<
    SaveType | undefined
  >();

  const { fetchMore: fetchAssetsByField } = useSearchAssetsEsApiQuery({
    skip: true,
  });

  const { fetchMore: fetchVinDetails } = useGetDetailsFromVinQuery({
    skip: true,
  });

  const [presetsFromVin, setPresetsFromVin] = useState<
    PresetsFromVin | undefined
  >();

  const [hadVinAPIError, setHadVinAPIError] = useState(false);

  const formMethods = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      assetTypeId: undefined,
      customModel: "",
      customName: "",
      make: undefined,
      model: undefined,
      serial: "",
      vin: "",
      year: "",
    } as AddAssetFormFields,
  });

  const [createAsset] = useCreateAssetMutation();

  const {
    getValues,
    handleSubmit,
    reset: resetForm,
    trigger,
    watch,
  } = formMethods;

  const { showToastWithAction, showErrorToast } = useToastContext();

  useEffect(() => {
    if (!presetsFromVin?.hasValidationErrors) {
      formMethods.setFocus("vin", { shouldSelect: true });
    }
  }, [presetsFromVin, formMethods]);

  const onSubmit: SubmitHandler<AddAssetFormFields> = async (data) => {
    setPendingSaveAction(undefined);

    const response = await createAsset({
      variables: {
        input: {
          assetTypeId: data.assetTypeId!,
          customName: data.customName || null,
          makeId: data.make || null,
          model: data.customModel || null,
          modelId: data.model || null,
          serial: data.serial || null,
          vin: data.vin || null,
          year: data.year || null,
        },
      },
    });

    const assetId = response.data?.createAsset?.id;
    const assetText = data.customName || "An asset";

    if (assetId) {
      showToastWithAction({
        title: "New Asset Added!",
        text: `${assetText} has been created. Would you like to add additional details?`,
        actionText: "Add details",
        action: () => {
          sendMessageToParentWindow(
            replaceStringPart(T3_PATHNAME.EDIT_ASSET, assetId),
          );
        },
      });
    }
  };

  const handleUpdateVin = () => {
    setPresetsFromVin(undefined);
  };

  const handleContinueWithVinApiError = () => {
    // reset the vin api error state when the 'continue' button is pressed
    setHadVinAPIError(false);
    setPresetsFromVin({ ...presetsFromVin, hasApiErrors: false });
  };

  const checkDuplicateFields = async (field: SearchField, value: string) => {
    if (!value) {
      return false;
    }
    const { data: assetsByFieldData } = await fetchAssetsByField({
      variables: {
        input: {
          field,
          value,
          exactMatch: true,
        },
      },
    });
    const assets = assetsByFieldData.searchAssetsEsApi.items as Asset[];

    if (assets.length > 0) {
      field === SearchField.VIN
        ? setAssetsWithDuplicateVin(assets)
        : setAssetsWithDuplicateSerial(assets);
      return true;
    }

    return false;
  };

  const handleNext = async () => {
    const hasAssetType = await trigger("assetTypeId");
    await trigger("vin");

    const vin = getValues("vin");
    const hasDuplicateVin = await checkDuplicateFields(SearchField.VIN, vin);

    let hasValidationErrors = false;
    let hasApiErrors = false;

    // don't keep re-checking VIN if we've already had an API error
    // this lets the form progress if the user has already seen the error
    if (vin && !hadVinAPIError) {
      setIsCheckingVin(true);
      try {
        const {
          data: {
            getDetailsFromVin: {
              metadata,
              vehicle,
              errors: vinValidationErrors,
            },
          },
        } = await fetchVinDetails({ variables: { vin: vin } });

        hasValidationErrors = !!vinValidationErrors;

        setPresetsFromVin({
          makeId: metadata?.make?.makeId,
          makeName: metadata?.make?.name,
          modelId: metadata?.model?.modelId,
          year: vehicle?.modelYear,
          hasValidationErrors,
        });
      } catch (e) {
        setHadVinAPIError(true);
        hasApiErrors = true;
        console.error(e);
        setPresetsFromVin({ hasApiErrors });
      } finally {
        setIsCheckingVin(false);
      }
    } else {
      setPresetsFromVin(undefined);
    }

    if (
      hasAssetType &&
      !hasValidationErrors &&
      !hasApiErrors &&
      !hasDuplicateVin
    ) {
      setStep(FormStep.AssetDetailsStep);
    }
  };

  const returnToFirstStep = () => {
    setHadVinAPIError(false);
    setStep(FormStep.AssetTypeStep);
  };

  const cleanUpForm = () => {
    setPresetsFromVin(undefined);
    setHadVinAPIError(false);
    resetForm();
    returnToFirstStep();
  };

  const trackSave = (data: AddAssetFormFields, saveType: SaveType) => {
    heaperEvent(
      "Fleet Configuration Dashboard - Get Started - Click - Complete Add Assets Step",
      {
        asset_type_id: data.assetTypeId!,
        save_type: saveType,
      },
    );
  };

  const handleSave = async (skipDuplicateSerialCheck?: boolean) => {
    setPendingSaveAction(SaveType.SAVE);

    if (!skipDuplicateSerialCheck) {
      const hasDuplicateSerial = await checkDuplicateFields(
        SearchField.SERIAL,
        getValues("serial"),
      );

      if (hasDuplicateSerial) {
        // Don't attempt to save if there are duplicate serials
        return;
      }
    }

    await handleSubmit(async (data) => {
      try {
        await onSubmit(data);
        trackSave(data, SaveType.SAVE);
      } catch (e: any) {
        handleAPIError(e, showErrorToast);
        // return early to keep form open
        return;
      }
      handleCloseFlyout();
    })();
  };

  const handleSaveAndContinue = async (skipDuplicateSerialCheck?: boolean) => {
    setPendingSaveAction(SaveType.SAVE_AND_ADD_ANOTHER);

    if (!skipDuplicateSerialCheck) {
      const hasDuplicateSerial = await checkDuplicateFields(
        SearchField.SERIAL,
        getValues("serial"),
      );

      if (hasDuplicateSerial) {
        // Don't attempt to save if there are duplicate serials
        return;
      }
    }

    await handleSubmit(async (data) => {
      try {
        await onSubmit(data);
        trackSave(data, SaveType.SAVE_AND_ADD_ANOTHER);
      } catch (e: any) {
        handleAPIError(e, showErrorToast);
        return;
      }
      cleanUpForm();
    })();
    handleCloseAction();
  };

  const handleCloseFlyout = () => {
    closeFlyout();
    cleanUpForm();
    handleCloseAction();
  };

  let primaryAction, secondaryAction, tertiaryAction;

  if (currentStep === FormStep.AssetTypeStep) {
    primaryAction = {
      action: handleNext,
      isDisabled: presetsFromVin?.hasValidationErrors || !watch("assetTypeId"),
      isLoading: isCheckingVin,
      label: "Next",
    };
    tertiaryAction = { action: handleCloseFlyout };
  } else {
    primaryAction = { action: handleSave };
    secondaryAction = { action: handleSaveAndContinue };
    tertiaryAction = { action: returnToFirstStep, label: "Previous" };
  }

  return (
    <FormProvider {...formMethods}>
      <ActionCard
        handleAction={() => {
          heaperEvent(
            "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
            { step: "Quick Add Assets", source: "getStarted" },
          );
          openFlyout();
        }}
        state={state}
        subtitle="Give us the basic information around your assets to quickly get them into T3."
        title="Quick Add Assets"
      />
      <FlyoutForm
        flyoutBody={
          <AddAssetsForm
            handleContinueWithVinApiError={handleContinueWithVinApiError}
            handleUpdateVin={handleUpdateVin}
            onSubmit={() => handleSubmit(onSubmit)}
            presetsFromVin={presetsFromVin}
            setStep={setStep}
            step={currentStep}
            watch={watch}
          />
        }
        handleClose={handleCloseFlyout}
        hasRequiredFields
        headerText="Quick Add Asset"
        isVisible={isFlyoutVisible}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        tertiaryAction={tertiaryAction}
      />
      {assetsWithDuplicateVin?.length > 0 && (
        <FieldInUseModal
          assetsUsingField={assetsWithDuplicateVin}
          fieldName={FieldName.VIN}
          onClose={() => {
            setAssetsWithDuplicateVin([]);
          }}
          onContinue={() => {
            setAssetsWithDuplicateVin([]);
            if (
              !presetsFromVin?.hasValidationErrors &&
              !presetsFromVin?.hasApiErrors
            ) {
              setStep(FormStep.AssetDetailsStep);
            }
          }}
        />
      )}
      {assetsWithDuplicateSerial.length > 0 && (
        <FieldInUseModal
          assetsUsingField={assetsWithDuplicateSerial}
          fieldName={FieldName.SERIAL}
          onClose={() => {
            setAssetsWithDuplicateSerial([]);
          }}
          onContinue={() => {
            if (pendingSaveAction === SaveType.SAVE) {
              handleSave(true);
            } else if (pendingSaveAction === SaveType.SAVE_AND_ADD_ANOTHER) {
              handleSaveAndContinue(true);
            }
            setAssetsWithDuplicateSerial([]);
          }}
        />
      )}
    </FormProvider>
  );
};

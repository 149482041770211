import { useEuiTheme } from "@equipmentshare/ds2";
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@equipmentshare/ds2";
import { ArrowRightSLineIcon } from "@equipmentshare/ds2-icons";
import { Device, DeviceInstallStatus } from "@fleet-configuration/client";
import { DeviceImage, useFlyout } from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { LIGHT_BORDER } from "@fleet-configuration/utils";

import { DeviceInstallStatuses } from "@/components/device-installation-progress/your-devices-flyout/device-install-statuses";
import {
  TriggerSource,
  useInstallHelpWizard,
} from "@/components/device-installation-progress/your-devices-flyout/install-help-wizard";
import { CONSTANTS } from "@/config/constants";
import {
  AssignDevicesFlyoutProps,
  DeviceDetailsFlyoutProps,
} from "@/config/constants/flyout";
import { getDeviceImage } from "@/config/deviceImages";
import { usePendingAssignments } from "@/contexts";
import { DeviceKind } from "@/types";
import { getDeviceTypeDisplayName } from "@/utils/helpers";

export type DeviceCardProps = {
  device: Device;
};

export const DeviceCard = ({ device }: DeviceCardProps) => {
  const { euiTheme } = useEuiTheme();
  const {
    ModalComponent: InstallHelpWizard,
    openModal: openInstallHelpWizard,
  } = useInstallHelpWizard(device, TriggerSource.DEVICE_LIST);

  const { pendingAssignments } = usePendingAssignments();

  const {
    openFlyout: openAssignDevicesFlyout,
    setFlyoutProps: setAssignDevicesFlyoutProps,
  } = useFlyout<AssignDevicesFlyoutProps>(
    CONSTANTS.FlyoutContextKey.ASSIGN_DEVICES,
  );
  const {
    openFlyout: openDetailsFlyout,
    setFlyoutProps: setDetailsFlyoutProps,
  } = useFlyout<DeviceDetailsFlyoutProps>(
    CONSTANTS.FlyoutContextKey.DEVICE_DETAILS,
  );

  const onAssignClick = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Install Progress - Click - Assign to Asset",
      {
        device_name: device.typeName!,
        source: TriggerSource.DEVICE_LIST,
      },
    );
    openAssignDevicesFlyout();
    setAssignDevicesFlyoutProps({
      device,
      hideOverlay: true,
    });
  };

  const onDetailsClick = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Your Devices List - Click - Open Device Details",
      {
        device_name: device.typeName!,
      },
    );
    openDetailsFlyout();
    setDetailsFlyoutProps({
      hideOverlay: true,
      device,
    });
  };

  const imageMetadata = getDeviceImage(device);
  const typeName = getDeviceTypeDisplayName(device.typeName);
  const showInstallHelpButton =
    device.kind === DeviceKind.TRACKER &&
    device.installStatuses.includes(DeviceInstallStatus.Undetected);

  return (
    <EuiFlexGroup
      alignItems="center"
      data-testid="device-card"
      gutterSize="xs"
      style={{ borderBottom: `${LIGHT_BORDER}`, padding: "16px" }}
    >
      <DeviceImage alt={imageMetadata.alt} src={imageMetadata.src} />
      <EuiFlexGroup direction="column" gutterSize="xs">
        <EuiText size="xs">
          <h4>{device.deviceSerial}</h4>
        </EuiText>
        <EuiText color="subdued" data-testid="device-type" size="xs">
          <strong>{"Model: "}</strong>
          {`${typeName}`}
        </EuiText>
        <DeviceInstallStatuses
          deviceKind={device.kind}
          installStatuses={device.installStatuses}
          isPendingAssignment={!!pendingAssignments[device.esId]}
        />
        <EuiFlexGroup gutterSize="xs">
          {showInstallHelpButton && (
            <EuiButtonEmpty
              flush="left"
              onClick={openInstallHelpWizard}
              size="xs"
            >
              Install help
            </EuiButtonEmpty>
          )}
          {device.installStatuses.includes(DeviceInstallStatus.Unassigned) &&
            !pendingAssignments[device.esId] && (
              <EuiButtonEmpty
                aria-label="Assign to asset"
                onClick={onAssignClick}
                size="xs"
              >
                Assign to asset
              </EuiButtonEmpty>
            )}
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="View device details"
          iconSize="l"
          iconType={ArrowRightSLineIcon}
          onClick={onDetailsClick}
          style={{
            color: euiTheme.colors.subduedText,
          }}
        />
      </EuiFlexItem>
      <InstallHelpWizard />
    </EuiFlexGroup>
  );
};

"use client";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
} from "@equipmentshare/ds2";
import {
  ArrowRightSLineIcon,
  CheckboxCircleFillIcon,
  LockFillIcon,
} from "@equipmentshare/ds2-icons";
import { StepStatus } from "@fleet-configuration/client";
import { Tooltip } from "@fleet-configuration/components";
import { COLORS } from "@fleet-configuration/utils";

import { BaseActionCardProps } from "@/components/get-started/types";

type ActionCardProps = BaseActionCardProps & {
  title: string;
  subtitle: string;
  unavailableText?: string;
};

export const ActionCard = ({
  title,
  subtitle,
  handleAction,
  state,
  unavailableText,
}: ActionCardProps) => {
  const isUnavailable = state === StepStatus.Unavailable;
  const iconColor =
    state === StepStatus.Complete ? COLORS.SUCCESS : COLORS.LIGHT;

  const UnavailableIcon = () => {
    return (
      <Tooltip
        anchorPosition="upCenter"
        panelPaddingSize="s"
        title="Locked"
        trigger={
          <EuiIcon
            color={COLORS.LIGHT}
            data-testid="lock-icon"
            size="l"
            type={LockFillIcon}
          />
        }
      >
        <div style={{ width: "240px" }}>{unavailableText}</div>
      </Tooltip>
    );
  };

  const StateIcon = () => {
    if (isUnavailable) {
      return <UnavailableIcon />;
    } else {
      return (
        <EuiIcon
          color={iconColor}
          data-testid="checkmark-icon"
          size="l"
          type={CheckboxCircleFillIcon}
        />
      );
    }
  };

  return (
    <EuiPanel
      data-testid="action-card"
      disabled={isUnavailable}
      onClick={handleAction}
      style={{
        cursor: isUnavailable ? "not-allowed" : "pointer",
        padding: "12px 8px",
        textAlign: "start",
      }}
    >
      <EuiFlexGroup gutterSize="s" responsive={false}>
        <EuiFlexItem grow={false}>
          <StateIcon />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText>
            <strong>{title}</strong>
          </EuiText>
          <EuiText color="subdued" size="s">
            <p>{subtitle}</p>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem grow={false} style={{ justifyContent: "center" }}>
          <EuiIcon size="l" type={ArrowRightSLineIcon} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

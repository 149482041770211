import {
  EuiAccordion,
  EuiCheckbox,
  EuiFacetButton,
  EuiFacetGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiLink,
  EuiNotificationBadge,
  EuiSpacer,
  EuiText,
} from "@equipmentshare/ds2";
import { FacetEntry, FacetId } from "@fleet-configuration/client";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import { useEffect, useState } from "react";

import {
  facetPrettyNameMap,
  SelectedFacetEntryNames,
} from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filters";

export type FacetProps = {
  facetId: FacetId;
  formatEntry?: (entryName: string) => string;
  entries: FacetEntry[];
  selectedEntryNames?: SelectedFacetEntryNames;
  columns?: 1 | 2;
  onChange: (facet: FacetId, facetState: SelectedFacetEntryNames) => void;
};

export const Facet = ({
  columns = 2,
  facetId,
  onChange,
  entries,
  selectedEntryNames = [],
  formatEntry = (entry) => entry,
}: FacetProps) => {
  const facetName = facetPrettyNameMap[facetId];
  const [facetState, setFacetState] =
    useState<SelectedFacetEntryNames>(selectedEntryNames);
  const hasSelections = selectedEntryNames.length > 0;

  useEffect(() => {
    setFacetState(selectedEntryNames);
  }, [selectedEntryNames]);

  const updateFacetState = (newState: string[]) => {
    setFacetState(newState);
    onChange(facetId, newState);
  };

  const onSelectionChange = (facetEntry: FacetEntry) => {
    heaperEvent(
      "Fleet Configuration Dashboard - Device Filters - Click - Filter Option",
      {
        facet: facetId,
        value: facetEntry.name,
      },
    );

    const newState = facetState.includes(facetEntry.id)
      ? facetState.filter((entry) => entry !== facetEntry.id)
      : [...facetState, facetEntry.id];

    updateFacetState(newState);
  };

  const SelectAllButton = () => {
    const onClick = () => {
      const newState = entries
        .filter((entry) => entry.count > 0)
        .map((entry) => entry.id);
      updateFacetState(newState);
    };
    return <EuiLink onClick={onClick}>Select All</EuiLink>;
  };

  const DeselectAllButton = () => {
    const onClick = () => {
      const newState: string[] = [];
      updateFacetState(newState);
    };
    return <EuiLink onClick={onClick}>Deselect All</EuiLink>;
  };

  return (
    <EuiAccordion
      arrowDisplay="right"
      arrowProps={{ iconSize: "l" }}
      buttonContent={
        <EuiText size="s">
          <h3>{facetName}</h3>
        </EuiText>
      }
      extraAction={hasSelections ? <DeselectAllButton /> : <SelectAllButton />}
      id={facetId}
      initialIsOpen
    >
      <EuiSpacer size="m" />
      <EuiFacetGroup>
        <EuiFlexGrid columns={columns}>
          {entries.map((entry) => {
            const hasNoDevices = entry.count === 0;
            const isSelected = facetState.includes(entry.id);
            const badgeColor =
              isSelected && entry.count > 0 ? "accent" : "subdued";
            return (
              <EuiFacetButton
                data-testid={entry.id + "-facet-button"}
                icon={
                  <EuiCheckbox
                    aria-label={`Select ${entry.name}`}
                    checked={isSelected}
                    data-testid={entry.id + "-checkbox"}
                    disabled={hasNoDevices}
                    id={entry.id}
                    label=""
                    onChange={() => {
                      onSelectionChange(entry);
                    }}
                  />
                }
                isDisabled={hasNoDevices}
                isSelected={isSelected}
                key={entry.id}
                onClick={() => {
                  onSelectionChange(entry);
                }}
              >
                <EuiFlexGroup gutterSize="xs" responsive={false}>
                  <div className="block w-11/12 overflow-hidden text-ellipsis">
                    {formatEntry(entry.name)}
                  </div>
                  <div className="block">
                    <EuiNotificationBadge color={badgeColor} size="m">
                      {entry.count.toLocaleString()}
                    </EuiNotificationBadge>
                  </div>
                </EuiFlexGroup>
              </EuiFacetButton>
            );
          })}
        </EuiFlexGrid>
      </EuiFacetGroup>
    </EuiAccordion>
  );
};

"use client";

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiLink,
  EuiPanel,
  EuiText,
  htmlIdGenerator,
} from "@equipmentshare/ds2";
import { InformationLineIcon } from "@equipmentshare/ds2-icons";
import { useCompleteInstallDevicesMutation } from "@fleet-configuration/client";
import {
  DeviceImage,
  FlyoutForm,
  useFlyout,
  useToastContext,
} from "@fleet-configuration/components";
import { heaperEvent } from "@fleet-configuration/heaper-utils";
import {
  EXTERNAL_URLS,
  useParentWindowCommunication,
} from "@fleet-configuration/utils";
import { useEffect } from "react";

import { ActionCard } from "@/components/get-started/action-cards/action-card";
import { TrackerInstallListItem } from "@/components/get-started/action-cards/install-devices/tracker-install-list-item";
import { GetStartedCardProps } from "@/components/get-started/types";
import { CONSTANTS } from "@/config/constants";
import { useGetTrackerInstallationGuides } from "@/hooks/useGetTrackerInstallationGuides";
import { GQL } from "@/types";
import { handleAPIError } from "@/utils/validation";

type InstallDevicesCardProps = GetStartedCardProps;

export const InstallDevicesCard = ({
  handleCloseAction = () => {},
  state,
}: InstallDevicesCardProps) => {
  const handleContactCustomerSupport = () => {
    heaperEvent(
      "Fleet Configuration Dashboard - Install Trackers - Click - Contact Customer Support",
      {},
    );
    notifyParentWindow();
  };

  const { isFlyoutVisible, openFlyout, closeFlyout } = useFlyout(
    CONSTANTS.FlyoutContextKey.INSTALL_DEVICES,
  );

  const handleCloseFlyout = () => {
    handleCloseAction();
    closeFlyout();
  };
  const { notifyParentWindow } = useParentWindowCommunication({
    sourceApp: CONSTANTS.APP_NAME,
    parentOrigin: EXTERNAL_URLS.T3,
    type: CONSTANTS.PARENT_WINDOW_MESSAGE_TYPES
      .T3_IFRAME_TRIGGERED_INTERCOM_MESSAGE,
  });

  const { showToast, showErrorToast } = useToastContext();

  const {
    trackerInstallationGuides,
    loading: isLoadingTrackerInstallationGuides,
    error: trackerInstallationGuidesError,
  } = useGetTrackerInstallationGuides();

  const [completeInstallDevicesMutation] = useCompleteInstallDevicesMutation({
    variables: {},
  });

  /**
   * Complete the install devices task if it is not already complete
   */
  useEffect(() => {
    const completeInstallation = async () => {
      if (isFlyoutVisible && state !== GQL.StepStatus.Complete) {
        try {
          await completeInstallDevicesMutation();
        } catch (error) {
          handleAPIError(error, showErrorToast);
          return;
        }
      }
    };
    completeInstallation();
  }, [completeInstallDevicesMutation, showErrorToast, state, isFlyoutVisible]);

  /**
   * This useEffect will show a toast message if there was an issue identifying the user's owned devices
   */
  useEffect(() => {
    if (
      isFlyoutVisible &&
      !isLoadingTrackerInstallationGuides &&
      trackerInstallationGuidesError
    ) {
      showToast({
        color: "warning",
        title: "Notice",
        text: "There was an issue identifying your owned devices. You still have access to all available device installation guides.",
      });
    }
    // Excluding 'showToast' from the dependencies array to prevent infinite loop
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFlyoutVisible,
    isLoadingTrackerInstallationGuides,
    trackerInstallationGuidesError,
  ]);

  const renderTrackerInstallListItems = () => {
    return Array.from(trackerInstallationGuides?.values() ?? []).map(
      (guide) => {
        return (
          <TrackerInstallListItem
            description={guide.description}
            guideUrl={guide.guideUrl}
            image={
              <DeviceImage alt={guide.imgData.alt} src={guide.imgData.src} />
            }
            isOwned={guide.isOwned}
            key={htmlIdGenerator("DeviceType")()}
            name={guide.model}
          />
        );
      },
    );
  };

  return (
    <>
      <ActionCard
        handleAction={() => {
          heaperEvent(
            "Fleet Configuration Dashboard - Get Started - Click - Configuration Step",
            { step: "Install Devices", source: "getStarted" },
          );
          openFlyout();
        }}
        state={state}
        subtitle="Learn more about your trackers and how to self-install."
        title="Install Devices"
      />
      <FlyoutForm
        flyoutBody={
          <EuiFlexGroup
            data-testid="install-devices-flyout"
            direction="column"
            gutterSize="none"
            style={{ marginLeft: "-24px", marginRight: "-24px" }}
          >
            {!isLoadingTrackerInstallationGuides &&
              renderTrackerInstallListItems()}
            <EuiFlexGroup style={{ margin: "10px 20px" }}>
              <EuiPanel
                borderRadius="none"
                color="primary"
                grow={true}
                hasShadow={false}
                paddingSize="none"
              >
                <EuiCallOut
                  color="primary"
                  iconType={InformationLineIcon}
                  title="Don't see your tracker type?"
                >
                  <EuiText size="s">
                    If the tracker type you are looking for isn&apos;t listed{" "}
                    <EuiLink onClick={handleContactCustomerSupport}>
                      contact customer support
                    </EuiLink>
                    .
                  </EuiText>
                </EuiCallOut>
              </EuiPanel>
            </EuiFlexGroup>
          </EuiFlexGroup>
        }
        handleClose={handleCloseFlyout}
        headerText="Device Types"
        isVisible={isFlyoutVisible}
        secondaryAction={{ action: handleCloseFlyout, label: "Close" }}
        subHeader={
          <EuiText>
            <p data-testid="overview-text">
              Learn more about your various devices and how to self-install.
            </p>
          </EuiText>
        }
      />
    </>
  );
};

import { GQL, Permission, Role } from "@/types";

export const permissionsAvailableToAllUsers = [
  Permission.READ_ONLY,
  Permission.HIDE_ASSET_DETAILS,
  Permission.CAN_RENT,
];

function isCompanyOwner(user: GQL.User) {
  return user.permissions.isCompanyOwner;
}

function isManagerOrAdmin(role: Role) {
  return [Role.ADMIN, Role.MANAGER].includes(role);
}

function canGrantPermissions(currentUser: GQL.User, newUserRole: Role) {
  return (
    isManagerOrAdmin(newUserRole) &&
    (currentUser.permissions.canGrantPermissions || isCompanyOwner(currentUser))
  );
}

export function canGrantReadAssetFinancialRecords(
  currentUser: GQL.User,
  newUserRole: Role,
) {
  if (canGrantPermissions(currentUser, newUserRole)) {
    return (
      currentUser.permissions.canReadAssetFinancialRecords ||
      isCompanyOwner(currentUser)
    );
  }
  return false;
}

export function canGrantCreateAssetFinancialRecords(
  currentUser: GQL.User,
  newUserRole: Role,
) {
  if (canGrantPermissions(currentUser, newUserRole)) {
    return (
      currentUser.permissions.canCreateAssetFinancialRecords ||
      isCompanyOwner(currentUser)
    );
  }
  return false;
}

export function canGrantAccessCamera(currentUser: GQL.User, newUserRole: Role) {
  if (canGrantPermissions(currentUser, newUserRole)) {
    return (
      currentUser.permissions.canAccessCamera || isCompanyOwner(currentUser)
    );
  }
  return false;
}

export function canGrantGrantPermissions(
  currentUser: GQL.User,
  newUserRole: Role,
) {
  return isCompanyOwner(currentUser) && isManagerOrAdmin(newUserRole);
}

export const getGrantablePermissions = (
  currentUser: GQL.User,
  newUserRole: Role,
): Permission[] => {
  const permissionsForUser = [...permissionsAvailableToAllUsers];

  if (canGrantGrantPermissions(currentUser, newUserRole)) {
    permissionsForUser.push(Permission.CAN_GRANT_PERMISSIONS);
  }

  if (canGrantReadAssetFinancialRecords(currentUser, newUserRole)) {
    permissionsForUser.push(Permission.CAN_READ_ASSET_FINANCIAL_RECORDS);
  }

  if (canGrantCreateAssetFinancialRecords(currentUser, newUserRole)) {
    permissionsForUser.push(Permission.CAN_CREATE_ASSET_FINANCIAL_RECORDS);
  }

  if (canGrantAccessCamera(currentUser, newUserRole)) {
    permissionsForUser.push(Permission.CAN_ACCESS_CAMERA);
  }

  return permissionsForUser;
};

import { EuiFlexGroup } from "@equipmentshare/ds2";
import { FacetId } from "@fleet-configuration/client";
import { FullWidthDivider } from "@fleet-configuration/components";
import { capitalize } from "lodash";

import { Facet } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/facet";
import { WithFilterService } from "@/components/device-installation-progress/your-devices-flyout/filters-flyout/filter-button";
import { getDeviceTypeDisplayName } from "@/utils/helpers";

export enum FacetPrettyName {
  DeviceKind = "Device Type",
  DeviceType = "Device Model",
  DeviceInstallStatus = "Device Status",
}

export const facetPrettyNameMap = {
  [FacetId.DeviceKind]: FacetPrettyName.DeviceKind,
  [FacetId.DeviceType]: FacetPrettyName.DeviceType,
  [FacetId.DeviceInstallStatus]: FacetPrettyName.DeviceInstallStatus,
};

export type SelectedFacetEntryNames = string[];

export type FilterState = {
  [FacetId.DeviceKind]?: SelectedFacetEntryNames;
  [FacetId.DeviceType]?: SelectedFacetEntryNames;
  [FacetId.DeviceInstallStatus]?: SelectedFacetEntryNames;
};

export const Filters = ({ filterService }: WithFilterService) => {
  const { filterState, updateFilters } = filterService;
  const onFilterChange = (
    facet: FacetId,
    selectedEntryNames: SelectedFacetEntryNames,
  ) => {
    updateFilters({
      ...filterState,
      [facet]: selectedEntryNames,
    });
  };

  return filterState ? (
    <EuiFlexGroup direction="column">
      <Facet
        entries={
          filterService.facets.find(
            (facet) => facet.facetId === FacetId.DeviceKind,
          )?.entries || []
        }
        facetId={FacetId.DeviceKind}
        formatEntry={capitalize}
        onChange={onFilterChange}
        selectedEntryNames={filterState[FacetId.DeviceKind]}
      />
      <FullWidthDivider />
      <Facet
        entries={
          filterService.facets.find(
            (facet) => facet.facetId === FacetId.DeviceType,
          )?.entries || []
        }
        facetId={FacetId.DeviceType}
        formatEntry={getDeviceTypeDisplayName}
        onChange={onFilterChange}
        selectedEntryNames={filterState[FacetId.DeviceType]}
      />
      <FullWidthDivider />
      <Facet
        columns={1}
        entries={
          filterService.facets.find(
            (facet) => facet.facetId === FacetId.DeviceInstallStatus,
          )?.entries || []
        }
        facetId={FacetId.DeviceInstallStatus}
        formatEntry={capitalize}
        onChange={onFilterChange}
        selectedEntryNames={filterState[FacetId.DeviceInstallStatus]}
      />
      <FullWidthDivider />
    </EuiFlexGroup>
  ) : null;
};
